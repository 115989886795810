
import {
    addDoc, query, getDocs, getDoc, doc, updateDoc, where, limit, orderBy, setDoc, deleteDoc
} from 'firebase/firestore'

class Driver {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    async get () {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch(er) {
            console.log(er)
        }
    }

    orderBy (fieldOrder: string, direction: any) {
        return ({
            get: async () => {
                const q = query(this.collectionInstance, orderBy(fieldOrder, direction))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            }
        })
    }

    where(fieldPath: string, opStr: any, value: any) {
        return ({
            limit: (numLimit: number) => ({
                get: async () => {
                    const q = query(this.collectionInstance, where(fieldPath, opStr, value), limit(numLimit))
                    const querySnapshot = await getDocs(q)
                    return querySnapshot
                }
            }),
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
            where: (innerFieldPath: string, innerOpStr: any, innerValue: any) => {
                return {
                        get: async () => {
                            const q = query(
                                this.collectionInstance,
                                where(fieldPath, opStr, value),
                                where(innerFieldPath, innerOpStr, innerValue)
                            )
                            const querySnapshot = await getDocs(q)
                            return querySnapshot
                        },
                }
            },
        })
    }

    doc(docId: string): any {
        if (!docId) {
            return addDoc(this.collectionInstance, {}).then(data => {
                return data
            })
        } else {
            return ({
                set: async (data: any, options?: any) => {
                     const ref = doc(this.collectionInstance, docId)
                     return setDoc(ref, data, options)
                },
                update: async (data: any) => {
                    try {
                        const ref = doc(this.collectionInstance, docId)
                        return await updateDoc(ref, data)
                    } catch (err) {
                        console.error(err)
                        return
                    }
                },
                delete: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    await deleteDoc(ref)
                },
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await getDoc(ref)
                    return document
                }
             })
        }
    }
}

export default (collection: any) => {
    return new Driver(collection)
}