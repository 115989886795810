import { createSlice, createAsyncThunk, configureStore } from '@reduxjs/toolkit'
import { GeoPoint } from 'firebase/firestore'
import { dedicatedStemsCollection, getCollection, getOrdersCollection, rateTableTMSCollection, substatsCollection, tmsConfigurationFieldsCollection } from '../../../services/firebase/firebaseConfig'

const initialState = {
    distributionzones: null,
    regions: null,
    vendors: null,
    orderstatusesref: null,
    lockedstatuses: null,
    cancellationreasons: null,
    ignoreStemStatuses: null,
    orderstatuses: null,
    opsstatusesref: null,
    fieldsnapshot: null,
    ordertypesref: null,
    loading: false,
}
const initialBillingState = {
    stemsMap: null,
    ratesArray: null,
    loading: false,
}

export const fetchData: any = createAsyncThunk(
    'data/fetchData',
    async (thunkAPI) => {
        // set time out for 10 seconds and then resolve promise
        const [distributionzones, regions, vendors, orderstatusesref, opsstatusesref, fieldsnapshot, ordertypesref] = await Promise.all(
            [
                getOrdersCollection(`${process.env.REACT_APP_COLLECTION_DISTRIBUTIONZONES}`).get(),
                getOrdersCollection('REGIONS').get(),
                getOrdersCollection('VENDORS').get(), //TODO REFACTOR: WE NEED THIS FETCHING JUST ONCE
                substatsCollection.get(), //TODO REFACTOR: WE NEED THIS FETCHING JUST ONCE
                getCollection("CONFIGURATIONS/OPTIONS/OpsStatuses").get(), //TODO REFACTOR: WE NEED THIS FETCHING JUST ONCE
                tmsConfigurationFieldsCollection.get(),
                getCollection("CONFIGURATIONS/OPTIONS/OrderType").get(),
            ])
        const lockedstatuses: any[] = []
        const cancellationreasons: any[] = []
        const orderstatuses: any[] = []
        const ignoreStemStatuses: any[] = []

        orderstatusesref?.docs.forEach((doc: any) => {
            if (doc.data().shouldLock) {
                lockedstatuses.push(doc.data().value)
            }
            if (doc.data().isCancellationReason) {
                cancellationreasons.push({
                    label: doc.data().value,
                    value: doc.id
                })
            }
            if (doc.data().isOrderStatus) {
                orderstatuses.push({
                    label: doc.data().value,
                    value: doc.id
                })
            }
            if(doc.data().ignoreStem){
                ignoreStemStatuses.push(doc.data().value)
            }
        })

        return {
            distributionzones: distributionzones?.docs?.map((d: any) =>
            ({
                label: d.data().DistributionZoneName,
                value: {id: d.id,
                    ...d.data(),
                    Center: {
                        _lat: d.data()?.Center?._lat,
                        _long: d.data()?.Center?._long
                    } //Firebase GeoPoint is not serializable
                }
            })).sort((a, b) => (a.label).localeCompare(b.label)) //TODO: need to refactor all Collection definition and set orderBy as default
            ,
            regions: regions?.docs?.map((d: any) => ({ value: { id: d.id, ...d.data()}, label: d.data().name.toUpperCase() })),
            vendors: vendors?.docs?.map((d: any) =>
            ({
                label: `${d.data().businessUnit}***${d.data().subBusinessUnit}`,
                value: {id: d.id, ...d.data()},
            })).sort((a, b) => (a.label).localeCompare(b.label))
            ,
            orderstatusesref: orderstatusesref?.docs?.map((d: any) =>
            ({
                label: d.data().value,
                value: { id: d.id, ...d.data() }
            })).sort((a, b) => (a.label).localeCompare(b.label))
            ,
            opsstatusesref: opsstatusesref?.docs?.map((d: any) =>
            ({
                label: d.data().Name,
                value: {
                    Id: d.id,
                    Status: d.data().Name,
                }
            })).sort((a, b) => (a.label).localeCompare(b.label)),
            fieldsnapshot: fieldsnapshot?.docs?.map((d: any) => ({...d.data(), id: d.id})),
            ordertypesref: ordertypesref?.docs?.map((d: any) =>
            ({
                label: d.data().Name,
                value: {id: d.id, ...d.data()}
            })).sort((a, b) => (a.label).localeCompare(b.label))
            ,
            lockedstatuses, cancellationreasons, orderstatuses, ignoreStemStatuses
        }
    }
)
const dataSlicer = createSlice({
    name: 'data',
    initialState,
    reducers: {
        updateOrderStatusesRef: (state, action: any) => {
        state.orderstatusesref = action.payload
      },
    },
    extraReducers: {
        [fetchData.pending]: (state) => {
            state.loading = true
        },
        [fetchData.fulfilled]: (state, { payload }) => {
            state.distributionzones = payload.distributionzones
            state.regions = payload.regions
            state.vendors = payload.vendors
            state.orderstatusesref = payload.orderstatusesref
            state.opsstatusesref = payload.opsstatusesref
            state.fieldsnapshot = payload.fieldsnapshot
            state.ordertypesref = payload.ordertypesref
            state.lockedstatuses = payload.lockedstatuses
            state.cancellationreasons = payload.cancellationreasons
            state.orderstatuses = payload.orderstatuses
            state.ignoreStemStatuses = payload.ignoreStemStatuses
            state.loading = false
        },
        [fetchData.rejected]: (state) => {
            state.loading = false
        },
    },
})


export const fetchBillingData: any = createAsyncThunk(
    'billingData/fetchBillingData',
    async (thunkAPI) => {
        let ratesArray: any = {}
        const [stems, ratesSnapshots] = await Promise.all(
            [
                dedicatedStemsCollection.getSimple(),
                rateTableTMSCollection.get()
            ]
        );
        //STEMS
        const stemsMap = stems?.docs.reduce((acc:any, stem:any) => {
            const _list = acc[stem.id] || []
            if (stem.data() && Object.keys(stem.data()).length) {
                Object.keys(stem.data()).forEach((city) => {
                    _list.push({ value: city, label: city })
                })
            }
            acc[stem.id] = _list
            return acc
        }, {})
        console.log("STEM", stemsMap)
        if (ratesSnapshots && ratesSnapshots?.docs?.length > 0) {
            ratesArray = ratesSnapshots?.docs.reduce((acc, curr) => {
                acc[curr.id] = curr?.data()
                return acc
            }, {})
        }
        return {
            stemsMap: JSON.stringify(stemsMap),
            ratesArray: JSON.stringify(ratesArray)
        }
    }
)
const billingDataSlicer = createSlice({
    name: 'billingData',
    initialState: initialBillingState,
    reducers: {},
    extraReducers: {
        /* fetchBillingData */
        [fetchBillingData.pending]: (state) => {
            state.loading = true
        },
        [fetchBillingData.fulfilled]: (state, { payload }) => {
            state.stemsMap = payload.stemsMap
            state.ratesArray = payload.ratesArray
            state.loading = false
        },
        [fetchBillingData.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const { updateOrderStatusesRef } = dataSlicer.actions

export const store = configureStore({
    reducer: {
        data: dataSlicer.reducer,
        billingData: billingDataSlicer.reducer
    }
});