import { collection, addDoc, query, getDocs, doc, setDoc, updateDoc, getDoc, deleteDoc, where, limit, orderBy } from 'firebase/firestore'

class RateConditions {
    collectionInstance: any
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    async get() {
        try {
            const q = query(this.collectionInstance, orderBy("value"))
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }

    async getSimple() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }


    where(fieldPath: string, opStr: any, value: any) {
        return ({
            limit: (numLimit: number) => ({
                get: async () => {
                    const q = query(this.collectionInstance, where(fieldPath, opStr, value), limit(numLimit))
                    const querySnapshot = await getDocs(q)
                    return querySnapshot
                }
            }),
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
        })
    }

    doc(docId?: string): any {
        if (!docId) {
            return addDoc(this.collectionInstance, {}).then((data) => {
                return data
            })
        } else {
            return {
                set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    return setDoc(ref, data, options)
                },
                update: async (data: any) => {
                    try {
                        const ref = doc(this.collectionInstance, docId)
                        return await updateDoc(ref, data)
                    } catch (err) {
                        console.error(err)
                        return
                    }
                },
                collection: (collectionId: string) => ({
                    add: async (data: any) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return addDoc(coll, data)
                    },
                }),
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await getDoc(ref)
                    return document
                },
                delete: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    await deleteDoc(ref)
                    return true
                }
            }
        }
    }
}

export default (collection: any) => {
    return new RateConditions(collection)
}
