import { SET_DID_SET_INTERVAL_RUN, UPDATE_PORTAL, UPDATE_AUTH_STATE } from './userActions.types'
const updateAuthState = (payload: any, state: any) => {
    const {
        apiKeys,
        countryCode,
        email,
        firstName,
        isDriver,
        isSBCustomer,
        isVendor,
        lastName,
        phone,
        role,
        stores,
        driverProfileDetails,
        vendorProfileDetails,
        visibilityThreshold,
        regions,
        user,
    } = payload
    return {
        ...state,
        user,
        apiKeys,
        countryCode,
        email,
        firstName,
        isDriver,
        isSBCustomer,
        isVendor,
        lastName,
        phone,
        role,
        stores,
        driverProfileDetails,
        vendorProfileDetails,
        visibilityThreshold,
        regions,
    }
}
const setDidSetIntervalRun = (payload: any, state: any) => {
    return {
        ...state,
        didSetIntervalRun: payload,
    }
}
const setPortal = (payload: any, state: any) => {
    return {
        ...state,
        portal: payload,
    }
}
export const UserReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_AUTH_STATE:
            return updateAuthState(action.payload, state)
        case SET_DID_SET_INTERVAL_RUN:
            return setDidSetIntervalRun(action.payload, state)
        case UPDATE_PORTAL:
            return setPortal(action.payload, state)
        default:
            return state
    }
}
