export default {
    language: 'Language',
    'Coming Soon': 'Coming Soon!',
    login: {
        selectStore: 'Select Store',
        selectRegion: 'Select Region',
        selectRole: 'Select Your Role',
        placeholder: {
            email: 'Email..',
            password: 'Password..',
            verification: 'Please enter your verification code here.',
        },
        rules: {
            characters: '8 Characters',
            number: 'Contains Number',
            uppercase: 'Contains Uppercase',
            specialCharacters: 'Contains Special Character',
        },
        messages: {
            verification: 'We just sent you a verification code.',
            captchaExpired: 'Session expired. Please attempt again.',
            redirectingToPortal: 'Hang tight, we are redirecting you to the portal.',
            emailVerification: 'Email verification is sent. Please check your inbox.',
            invalidAccount: 'Invalid Account.',
            unsupportedAccess: 'Unsupported access. Please login agian.',
            enterVerficationCode: 'Please enter your verification code.',
            somethingWentWrong: 'Something went wrong. Please try again.',
            standardRate: 'Standard rates may apply.',
            firstTimeLogin: 'Have you made an account before?',
            goBackToLogin: 'Go to login',
            goBackToSignup: 'Go to sign-up',
        },
        button: {
            submit: 'submit',
            login: 'Log In',
            signup: 'Create your account',
        },
        errorCode: {
            'auth/wrong-password': 'The password is invalid or the user does not have a password.',
            'auth/user-not-found':
                'There is no user record corresponding to this identifier. The user may have been deleted.',
            'auth/invalid-email': 'The email address is badly formatted.',
        },
        twofa: {
            secureAccess: 'Secure Portal',
            sendBtn: 'Send an activation code',
        },
    },
    nav: {
        addOrder: 'Add Order',
        search: 'Search',
        orders: 'Orders',
        logout: 'Log Out',
        drawer: {
            handleUiModeMsg: `{{mode}} mode is enabled.`,
            info: 'Info',
            orders: 'Records',
            routes: 'Routes',
            mapFleet: 'Hubs & Fleet',
            billingModule: 'Billing Module',
            invoice: 'Invoice',
            home: 'Home',
            search: 'Search',
            liveConnect: 'Live Connect',
            newOrder: 'New Order',
            history: 'History',
            report: 'Analytics',
            admin: 'Admin',
            logout: 'Logout',
            reverseLogistics: 'Reverse Logistics',
            language: 'Language',
            installations: 'Installations',
            logistics: 'Logistics',
            services: 'Services',
            mode: 'Mode',
        },
    },
    search: {
        title: {
            title: 'You can only search orders that are approved',
            subtitle1: 'To search pending orders, head over to ',
            subtitle2: 'pending orders',
            msg: '* Using date range is recommended for better performance and efficiency',
        },
        label: {
            or: 'Or',
            firstName: 'First Name',
            lastName: 'Last Name',
            phoneNumber: 'Phone Number',
            address: 'Address',
            invoice: 'Type Invoice # and press enter',
            from: 'From (Start Window)',
            to: 'To (Start Window)',
            deliveryDate: 'Delivery Date',
            selectStatus: 'Select Status',
            productName: 'Product Name',
            barcode: 'Barcode',
            whid: 'WHID',
            sku: 'SKU',
            invoiceInput: 'Invoice',
            poNumber: 'PO Number',
            'Scanned In': 'Scanned In',
            'Scanned Out': 'Scanned Out',
        },
        button: {
            reset: 'RESET',
            search: 'SEARCH',
        },
        messages: {
            recordsFound: 'record(s) found',
            noRecordsFound: 'No records found',
            noInputErr: 'Please type at least one keyword to search orders.',
            otherErr: 'Something went wrong. Please contact Shyftbase.',
        },
        'Customer Info': 'Customer Info',
        'Order Details': 'Order Details',
        Statuses: 'Order Statuses',
        Products: 'Products',
        'Reverse Logistics': 'Reverse Logistics',
        'Client Search': 'Client',
        'Zone Search': 'Zone',
        'Minimize Tags': 'Minimize Tags',
        'Show All Tags': 'Show All Tags',
        'Type to show or hide fields': 'Type to show or hide fields',
        'click to show': 'click to show',
        'click to hide': 'click to hide',
        selected: 'selected',
        'Search For Anything': 'Search For Anything',
        'Search Input Placeholder': 'Example: Order #, Customer Name, Client, PO #, Product, Barcode, WHID, SKU',
    },
    liveConnect: {
        orderType: {
            needAttention: 'Need Attention',
            pending: 'Pending',
            approved: 'Approved',
            cancelled: 'Cancelled',
            customerRequest: 'Customer Request',
        },
        region: 'Region',
        store: 'Store',
        invoice: 'Invoice',
        filter: 'Filter Orders',
        loading: 'Loading Data ...',
    },
    liveOrdersContainer: {
        tooltip: {
            hideStops: 'hide stops',
            showStops: 'show stops',
        },
    },
    liveOrder: {
        orderType: {
            Delivery: 'Delivery',
            Pickup: 'Pickup',
            'Service Call': 'Service Call',
            'Store-Stop': 'Store-Stop',
        },
        cancellationReason: {
            RESCHEDULE: 'RESCHEDULE',
            'NO STOCK': 'NO STOCK',
            'CANCELLED BY CUSTOMER': 'CANCELLED BY CUSTOMER',
            'ADDRESS NOT FOUND': 'ADDRESS NOT FOUND',
            'ADULT NOT PRESENT': 'ADULT NOT PRESENT',
            'PREVIOUSLY DONE': 'PREVIOUSLY DONE',
            'BOOKING ERROR': 'BOOKING ERROR',
            'CANCELLED BY STORE': 'CANCELLED BY STORE',
            'CANCELLED TO RESCHEDULE': 'CANCELLED TO RESCHEDULE',
            'CREATED - NO STOCK': 'CREATED - NO STOCK',
            'DELIVERY TEAM ILLNESS / INJURY': 'DELIVERY TEAM ILLNESS / INJURY',
            'DUPLICATE ORDER': 'DUPLICATE ORDER',
            'FLY BY - NO PRODUCT AVAILABLE': 'FLY BY - NO PRODUCT AVAILABLE',
            'INCORRECT ADDRESS': 'INCORRECT ADDRESS',
            'INCORRECT ADDRESS - NOT LOADED ON TRUCK': 'INCORRECT ADDRESS - NOT LOADED ON TRUCK',
            'INSUFFICIENT MANPOWER': 'INSUFFICIENT MANPOWER',
            'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK': 'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK',
            'NAH - OUTSIDE WINDOW': 'NAH - OUTSIDE WINDOW',
            'NO STOCK - MID MILE DELAY': 'NO STOCK - MID MILE DELAY',
            'NO STOCK LOCATED AT HUB': 'NO STOCK LOCATED AT HUB',
            'OVERSIZED PRODUCTS(S)': 'OVERSIZED PRODUCTS(S)',
            'PRODUCT NOT LOADED': 'PRODUCT NOT LOADED',
            'PROPERTY DAMAGE': 'PROPERTY DAMAGE',
            'PROPERTY NOT ACCESSIBLE': 'PROPERTY NOT ACCESSIBLE',
            'REFUSED - CONCEALED DAMAGE': 'REFUSED - CONCEALED DAMAGE',
            'REFUSED - DRIVER DAMAGE': 'REFUSED - DRIVER DAMAGE',
            'REFUSED - INCORRECT PRODUCT ORDERED': 'REFUSED - INCORRECT PRODUCT ORDERED',
            'REFUSED - INCORRECT PRODUCT SHIPPED': 'REFUSED - INCORRECT PRODUCT SHIPPED',
            'REFUSED - PRODUCT DEFECTIVE': 'REFUSED - PRODUCT DEFECTIVE',
            'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK': 'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK',
            'RESCHEDULED BY CUSTOMER': 'RESCHEDULED BY CUSTOMER',
            'VEHICLE ISSUES': 'VEHICLE ISSUES',
            'WEATHER - NOT LOADED ON TRUCK': 'WEATHER - NOT LOADED ON TRUCK',
            'WEATHER ISSUES': 'WEATHER ISSUES',
        },
        orderStatus: {
            'Need Attention': 'Need Attention',
            Pending: 'Pending',
            Cancelled: 'Cancelled',
            Approved: 'Approved',
        },
    },
    orderEntry: {
        viewPendingOrder: 'View Pending Orders',
        title: {
            create: 'Create New Order',
            update: 'Update Order',
        },
        subtitle: {
            zone: 'Zone : ',
            pickupDate: 'Pickup Date',
            deliveryDate: 'Delivery Date',
            scheduledDate: 'Scheduled Date',
            services: 'Services',
            products: 'Products',
        },
        placeholder: {
            placeOrderTakingTimeMessage: 'This may take a few seconds',
            select: 'Please Select Order Type',
            postalCode: 'Customer Postal Code (Example: L0C1E0)',
            selectReturn: 'Select Return Type',
            selectedDate: 'Selected Date: ',
            additionalInfo: 'Additional Info',
        },
        caution: {
            first: 'If scheduling a',
            second: ' haulaway ',
            third: ' , please select',
            fourth: ' service only',
            fifth: 'option.',
        },
        button: {
            check: 'Check Availabilities',
            submit: 'Submit',
        },
        messages: {
            submitError:
                'Sorry, booking for this postal code is not possible at this time! Please check the postal code you entered. If you believe this is a mistake, please make a request for this postal code to be added to the supported zones by sending an email to hcustome@bestbuycanada.ca',
            selectActiveDate: 'Please select active date',
            saveProduct: 'Please make sure products are saved!',
            saveService: 'Please make sure service is saved!',
            selectDate: 'Please make sure date is selected!',
            validInvoice: 'Please make sure invoice is valid!',
            validInvoiceFormat: 'Please make sure invoice format is "XXX-XXXXXX" in numbers',
            validPhone: 'Please make sure phone is valid!',
            validFirstName: 'Please make sure first name is valid!',
            validLastName: 'Please make sure last name is valid!',
            validStreet: 'Please make sure street is valid!',
            validCity: 'Please make sure city is valid!',
            validUnit: 'Please make sure unit number is valid!',
            orderAdded: 'Order was added to the pending list of orders!',
            orderUpdated: 'Order was successfully updated!',
            somethingWentWrong: 'Sorry, something went wrong. Please close this message and submit again.',
            lanudryAndFridgeDoorMsg:
                'Please confirm the unit being serviced is new or purchased and delivered by Metro To Home within 30 days.',
            isAccessoriesNew: "Are all the accessories new or used? Click 'Yes' if 'New', 'No' if 'Used'.",
            haulawayMsg:
                'Please note you have selected a haulaway service which will send the unit for recycling. Please ensure the customer uninstalls the unit and prepares it for pickup before our team arrives.',
            largeHawlawayMsg:
                'please note our haulaway service is a one for one service. Please review the quantity requested and resubmit.',
            generateLargeQtyMessage:
                'This is not a regular size home delivery, as such please make sure to follow the large order process on ETK.',
            generateFourManMessage: '4-man delivery: Please make sure to provide details, SKU and QTY in the comments.',
            generateReturnMessage:
                'Return to DC - Please make sure to provide details (REASON FOR RETURN) in the comments.',
            serviceConditionRequirement: 'service condition requirement is not met.',
            generateInvoiceAuthMessage: 'Sorry, only Admin has the permission to create 900 orders.',
            oversizeFridge: 'Please provide the details and the SKU / QTY in the comments.',
            over90Days:
                'You have selected a date that is over 90 days threshold. Please make sure the selected date is correct.',
            usedAccessories:
                'Installation accessories must be new and unopen in order for our home delivery provided to complete the installation.',
            yes: 'Yes',
            no: 'No',
        },
        options: {
            flyby: 'Fly By',
            serviceCall: 'Service Only',
            pickup: 'Pickup',
        },
    },
    serviceList: {
        service: 'Service',
        qty: 'QTY',
        remove: 'Remove',
    },
    saveServiceForm: {
        selectService: 'Select Service',
        saveService: 'Save Service',
        qty: 'QUANTITY',
        options: {
            fourMan: '4-man delivery-Specify details in comments',
            LevelUnit: 'Level Unit',
            MoveUnit: 'Move Unit in Place',
            Pedestal: 'Pedestal set up',
            StackingLaundry: 'Stacking Laundry',
            WasherConnection: 'Washer Connection',
            DryerConnection: 'Dryer Connection',
            WasherandDryerConnection: 'Washer and Dryer Connection',
            FridgeDoorSwing: 'Fridge Door Swing',
            OversizeFridge: 'Oversize Fridge (+26/350LB)',
            Haulaway: 'Haulaway',
            Doorstep: 'Doorstep',
        },
    },
    productList: {
        product: 'Product',
        qty: 'QTY',
        sku: 'SKU',
        model: 'MODEL',
        remove: 'remove',
    },
    saveProductForm: {
        name: 'PRODUCT NAME',
        qty: 'QUANTITY',
        sku: 'SKU',
        model: 'MODEL',
        save: 'Save Product',
    },
    history: {
        loading: 'Loading...',
        filter: 'filter',
        group: 'group orders',
        sort: 'sort orders',
    },
    calendarSettings: {
        title: {
            selectRegion: 'Calendar Setting - Select region to start',
        },
        placeholder: {
            selectZone: 'Select Zone',
            selectMonth: 'select month',
            selectBDays: 'select business days',
        },
        setMaxBooking: 'Set max booking date',
        subSetMaxBooking: '(How far ahead should customers be able to book)',
        monthFromToday: 'month(s) from today',
        submit: 'submit',
        setMinBooking: 'Set earliest available date',
        subSetMinBooking:
            '(eg: 2 means the earliest available date will be after 2 business days from the date the delivery is being places)',
        includingToday: 'business days including today',
        messages: {
            changePostalCode:
                'Customized days above override the postal code schedules. If you need to change postal code schedules please email us at admin@shyftbase.com.',
            followingDate: 'the following date ',
            default: 'Change back to default service schedule based on postal codes.',
        },
        colorCodes: 'Color codes: ',
        disabledDays: 'disabled days',
        enabledDays: 'enabled days',
        select: 'select',
        enable: 'enable',
        disable: 'disable',
    },
    distributionZoneMapping: {
        subtitle: 'Distribution Zone Mapping - Select region to start',
        'Select Region': 'Select Region',
        'Select Distribution Zones': 'Type and Search Distribution Zone',
        'Currently Mapped Distribution Zones': 'Currently Mapped Distribution Zones',
        Update: 'Update',
        Reset: 'Reset',
        None: 'None',
        Updated: 'Updated {0} Distribution Zones',
        Added: 'Added {0} to Regions List',
    },
    clientSetting: {
        error: {
            IncompletForm: 'Form is incomplete! Business Unit, Sub Business Unit, Regions, and Data Source cannot be empty'
        }
    },
    accessTable: {
        accessLevels: 'Access Levels',
        toggle: 'Toggle Access Hierarchy',
        save: 'Save',
        searchByName: 'Search by name...',
        noUser: 'No users found',
        emailAdmin: '',
        name: 'Name',
        access: 'Access',
        'Create a new one': 'Create a new one',
        'User access updated': 'User access updated',
        Region: 'Region',
        Actions: 'Actions',
        Client: 'Client',
        Vendor: 'Client',
    },
    updateOrderInfo: {
        title: 'Update Order',
        startWindow: 'Start Window',
        selectedDate: 'Selected Date: ',
        services: 'Services',
        products: 'Products',
        additionalInfo: 'Additional Info',
        submit: 'Submit',
        ok: 'OK',
        messages: {
            somethingWentWrong: 'Something went wrong! Please try again.',
            pending: 'Order was update to the pending list of orders!',
        },
    },
    orderDetailModal: {
        requestInfo: 'Request Info (Move To Need Attention)',
        typeMessage: 'Please type your messeage',
        approve: 'Approve Order',
        areYouSure: 'Are you sure?',
        yes: 'Yes',
        no: 'No',
        cancelOrder: 'Cancel Order',
        update: 'Update',
        selectAction: 'Select Action/View Details',
    },
    orderDetail: {
        invoice: 'Invoice / Order Number',
        customer: 'Customer',
        email: 'Email',
        phone1: 'Phone 1',
        phone2: 'Phone 2',
        products: 'Products',
        services: 'Services',
        startWindow: 'Start Window',
        address: 'Address',
        pickupAddress: 'Pickup Address',
        dispatchComments: 'Dispatch Comments',
    },
    cancellationHandler: {
        selectReasonTitle: 'Select Reason',
        options: {
            RESCHEDULE: 'RESCHEDULE',
            'NO STOCK': 'NO STOCK',
            'CANCELLED BY CUSTOMER': 'CANCELLED BY CUSTOMER',
            'ADDRESS NOT FOUND': 'ADDRESS NOT FOUND',
            'ADULT NOT PRESENT': 'ADULT NOT PRESENT',
            'PREVIOUSLY DONE': 'PREVIOUSLY DONE',
            'BOOKING ERROR': 'BOOKING ERROR',
            'CANCELLED BY STORE': 'CANCELLED BY STORE',
            'CANCELLED TO RESCHEDULE': 'CANCELLED TO RESCHEDULE',
            'CREATED - NO STOCK': 'CREATED - NO STOCK',
            'DELIVERY TEAM ILLNESS / INJURY': 'DELIVERY TEAM ILLNESS / INJURY',
            'DUPLICATE ORDER': 'DUPLICATE ORDER',
            'FLY BY - NO PRODUCT AVAILABLE': 'FLY BY - NO PRODUCT AVAILABLE',
            'INCORRECT ADDRESS': 'INCORRECT ADDRESS',
            'INCORRECT ADDRESS - NOT LOADED ON TRUCK': 'INCORRECT ADDRESS - NOT LOADED ON TRUCK',
            'INSUFFICIENT MANPOWER': 'INSUFFICIENT MANPOWER',
            'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK': 'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK',
            'NAH - OUTSIDE WINDOW': 'NAH - OUTSIDE WINDOW',
            'NO STOCK - MID MILE DELAY': 'NO STOCK - MID MILE DELAY',
            'NO STOCK LOCATED AT HUB': 'NO STOCK LOCATED AT HUB',
            'OVERSIZED PRODUCTS(S)': 'OVERSIZED PRODUCTS(S)',
            'PRODUCT NOT LOADED': 'PRODUCT NOT LOADED',
            'PROPERTY DAMAGE': 'PROPERTY DAMAGE',
            'PROPERTY NOT ACCESSIBLE': 'PROPERTY NOT ACCESSIBLE',
            'REFUSED - CONCEALED DAMAGE': 'REFUSED - CONCEALED DAMAGE',
            'REFUSED - DRIVER DAMAGE': 'REFUSED - DRIVER DAMAGE',
            'REFUSED - INCORRECT PRODUCT ORDERED': 'REFUSED - INCORRECT PRODUCT ORDERED',
            'REFUSED - INCORRECT PRODUCT SHIPPED': 'REFUSED - INCORRECT PRODUCT SHIPPED',
            'REFUSED - PRODUCT DEFECTIVE': 'REFUSED - PRODUCT DEFECTIVE',
            'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK': 'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK',
            'RESCHEDULED BY CUSTOMER': 'RESCHEDULED BY CUSTOMER',
            'VEHICLE ISSUES': 'VEHICLE ISSUES',
            'WEATHER - NOT LOADED ON TRUCK': 'WEATHER - NOT LOADED ON TRUCK',
            'WEATHER ISSUES': 'WEATHER ISSUES',
        },
    },
    orderInfo: {
        placeholder: {
            invoice: 'Invoice/value',
            firstName: 'First Name',
            lastName: 'Last Name',
            phone1: 'Phone 1',
            phone2: 'Phone 2',
            email: 'Email',
            appt: 'Appartment',
            unit: 'Unit #',
            street: 'Street / Drive',
            city: 'City / Town',
            postalCode: 'Postal Code',
        },
    },
    bbUserAccessCell: {
        selectOrderType: 'Please Select Order Type',
    },
    chatHistory: {
        title: 'Chat History',
        reply: 'Reply',
        submit: 'Send',
    },
    report: {
        dateRangeMsg: '* If date range is longer than 2 weeks, pleaes use `Download survey report - archive`',
        liveArchiveDataSourceMsg:
            '* To ensure you have the most recent results from the day before, please download the survey from the live data source.',
        download: 'Download survey reports',
        donwloadFromLiveData: 'Download survey report - live data',
        downloadData: 'Download data',
        viewReport: 'View Report',
        selectDateRange: 'Select Date Range',
        success: 'Report was successfully downloaded.',
        noDate: 'Date was not selected',
        noRecords: 'No records are found. Please set different date range and try again',
        noAuth: 'User not authorized. Only authorized staff can donwload report.',
        liveDataMsg:
            '* Live Data: Scope is limited to the live data only. Please use the archive extract of you need to download historical data.',
        'Download survey report - comments': 'Download survey report - comments',
    },
    fields: {
        Invoice: 'Invoice',
        OrderType: 'Order Type',
        StartWindow: 'Start Window',
        EndWindow: 'End Window',
        Products: 'Products',
        actions: 'Actions',
        OrderStatus: 'Order Status', // order status
        CustomerName: 'Customer Name',
        Phone1: 'Phone 1',
        Phone2: 'Phone 2',
        Email: 'Email',
        PickupAddress: 'Pickup Address',
        DropoffAddress: 'Dropoff Address',
        DriverNotes: 'Driver Notes',
        DriverServices: 'Driver Services',
        PlannedServices: 'Planned Services',
        StartedAt: 'Started At',
        ArrivedAt: 'Arrived At',
        CompletedAt: 'Completed At',
        BestBuyRobocallStatus: 'Robocall Status',
        BestBuySurveyQ1: 'Survey Q1',
        BestBuySurveyQ2: 'Survey Q2',
        BestBuySurveyQ3: 'Survey Q3',
        BestBuySurveyQ4: 'Survey Q4',
        BestBuySurveyQ5: 'Survey Q5',
        BestBuySurveyQ6: 'Survey Q6',
        BbGiftCustomerEmail: 'Gift Card Email',
        BbGiftAmount: 'Gift Card Amount',
        CancellationReason: 'Cancellation Reason',
        CalledCallCenter: 'Called CallCenter',
        BbCustomerEmailForSurvey: 'Survey Email',
        BbCustomerSurveyComment1: 'Survey Comment 1',
        BbCustomerSurveyComment2: 'Survey Comment 2',
        BestBuyRobocallTimestamp: 'Robocall Timestamp',
        Services: 'Services',
        Region: 'Region',
        DeliveryAssignee: 'Delivery Assignee',
        Vehicle: 'Vehicle',
        DispatchComments: 'Dispatch Comments',
        ServiceTime: 'Service Time',
        TotalTimeFromStart: 'Total Time From Start',
        // TextMsgLogs: "Text Message Logs",
        DispatchLog: 'Dispatch Log',
        Store: 'Store',
        Pod: 'POD',
        CustomerSignature: 'Customer Signature',
        DistributionZone: 'Distribution Zone',
        Client: 'Client',
    },
    vuetableDetailModal: {
        orderInfo: 'ORDER INFO',
        imageAttachment: 'IMAGE ATTACHMENT',
        productDetail: 'PRODUCT DETAIL',
        product: {
            Product: 'Product',
            QTY: 'QTY',
            SKU: 'SKU',
            WEIGHT: 'WEIGHT',
            MANUFACTURER: 'MANUFACTURER',
            VOLUME: 'VOLUME',
            SERIAL: 'SERIAL',
            MODEL: 'MODEL',
            BARCODE: 'BARCODE',
            COMMENT: 'COMMENT',
        },
        driverNotes: 'Driver Notes',
        textMsgLogs: 'Text Message Log',
        tooltip: {
            Name: 'Name',
            Phone: 'Phone',
            Products: 'Products',
            Accessorials: 'Accessorials',
            'Dispatch Comments': 'Dispatch Comments',
            'Delivery Address': 'Delivery Address',
            'Pickup Address': 'Pickup Address',
            'Start Window': 'Start Window',
        },
    },
    admin: {
        'Admin Settings': 'Settings',
        'Billing Module Settings': 'Billing module',
        'Your Business, Your Way': 'Your Business, Your Way',
        'Access Table': 'User Access Management',
        'Admin Action': 'Admin Action',
        'Calendar Settings': 'Calendar Settings',
        'Driver Settings': 'Driver',
        'Distribution Zone Mapping': 'Distribution Zone Mapping',
        'Reports Recipients Setting': 'Reports',
        'Client Settings': 'Customer',
        'Fleet Settings': 'Fleet',
        'Contactless Delivery Settings': 'Contactless Delivery',
        'Metro Driver Settings': 'Metro Driver',
        'Role Settings': 'Roles',
        'User Management': 'User Management',
        'Business Logic Center': 'Business Logic Center',
        'Client Profile Settings': 'Client Profiles',
        'Contractor Profile Settings': 'Contractor Profiles ',
        'General Settings': 'General',
        'Notification Settings': 'Notifications',
        ApiKeys: 'Manage API Keys',
        fields: {
            Yes: 'Yes',
            No: 'No',
            Cancel: 'Cancel',
            'Add New User': 'Add New User',
            'Create Vendor Profile': 'Create Client Profile',
            'Edit Vendor Profile': 'Edit Client Profile',
            'Delete Vendor Profile': 'Delete Client Profile',
            'Create Contractor Profile': 'Create Contractor Profile',
            'Edit Contractor Profile': 'Edit Contractor Profile',
            'Delete Contractor Profile': 'Delete Contractor Profile',
            'Business Unit': 'Business Unit',
            'Sub Business Unit': 'Sub Business Unit',
            'Company Name': 'Company Name',
            Active: 'Active',
            Escrow: 'Escrow',
            address: 'Address',
            allowAllRegion: 'allowAllRegion',
            billingCode: 'Billing Code',
            businessUnit: 'Business Unit',
            customerCanReschedule: 'CustomerCanReschedule',
            customerRescheduleAvailableDayThreshold: 'CustomerRescheduleAvailableDayThreshold',
            dataSource: 'DataSource',
            id: 'id',
            inventory: 'inventory',
            invoiceTemplate: 'InvoiceTemplate',
            logoDownloadURL: 'LogoDownloadURL',
            logoFileName: 'LogoFileName',
            regions: 'Regions',
            reverseLogistics: 'reverseLogistics',
            settlementTemplate: 'SettlementTemplate',
            shouldGeocode: 'shouldGeocode',
            shouldSendSurvey: 'shouldSendSurvey',
            subBusinessUnit: 'subBusinessUnit',
            trucks: 'Trucks',
            table: 'Table',
            tier: 'Tier',
            minPayable: 'Min Payable',
        },
    },
    driverSettings: {
        Create: 'Create',
        Update: 'Update',
        Delete: 'Delete',
        Confirm: 'Confirm',
        Created: 'Created {0}',
        Updated: 'Updated {0}',
        Deleted: 'Deleted',
        'Type new name here': 'Type new name here',
        'Type Delivery Assignee ID': 'Type Delivery Assignee ID',
        'is not found': 'is not found',
        'Type New Contractor To Create': 'Type New Contractor To Create',
        'No Driver was found in system': 'No Driver was found in system',
    },
    driverInfo: {
        Name: 'Name',
        Company: 'Company',
        Username: 'Username',
        Password: 'Password',
        'Fleet Id': 'Fleet Id',
        'Read Only': 'Read Only',
    },
    newUserForm: {
        'Create a new user': 'Create a new user',
        'First Name': 'First Name',
        'First name is required': 'First name is required',
        'Invalid first name': 'Invalid first name',
        'Last Name': 'Last Name',
        'Last name is required': 'Last name is required',
        'Invalid last name': 'Invalid last name',
        'Store/Admin': 'Store / Admin',
        'Store is required': 'Store is required',
        Admin: 'Admin',
        'Admin is required': 'Admin is required',
        Phone: 'Phone',
        'Phone is required': 'Phone is required',
        'Type only numbers without country code': 'Type only numbers without country code',
        Email: 'Email',
        'Email is required': 'Email is required',
        'Invalid email': 'Invalid email',
        Reset: 'Reset',
        'Create user': 'Create user',
        'saved user': ' is saved with success!',
        'Update User': 'Update User',
        Close: 'Close',
    },
    notification: {
        accessDenied: 'Access denied. Please check your access level.',
    },
    reports: {
        'Select Report Type': 'Report Type',
        'Currently Assigned Recipients': 'Currently Assigned Recipients',
        'Type email here': 'Type email here...',
        'Type Recipients Email and Hit Enter': "Type Recipient's Email and Hit Enter",
        'Reicipients list updated': 'Reicipients list updated',
    },
    Billing: {
        Hello: 'Hello',
        ViewInvoices: 'View Invoices',
        BackToOverView: 'Back To Overview',
        'Last Month Overview': 'Last Month Overview',
        'Select Month': 'Select Month',
        'Download Supporting Data': 'Download Supporting Data',
        'Download Invoice': 'Download Invoice',
        'Total Orders': 'Total Orders',
        Commingled: 'Commingled',
        Dedicated: 'Dedicated',
        orders: 'orders',
        stops: 'stops',
        Scanned: 'Scanned',
        Returns: 'Returns',
        'Driver Requests': 'Driver Requests',
    },
    forgotPassword: {
        'Reset password link was sent your email': 'Reset password link was sent your email',
        'Reset password': 'Reset password',
        'Please type your email': 'Please type your email',
        'forgot password': 'Forgot password?',
    },
    allFields: {
        actions: 'Actions',
        NumberOfDoorRemovals: 'Number Of Door Removals',
        NumberOfExtraPieces: 'Number Of Extra Pieces',
        NumberOfFridgeLineInstalls: 'Number Of Fridge Line Installs',
        NumberOfAdjustables: 'Number Of Adjustables',
        NumberOfPedInstalls: 'Number Of Ped Installs',
        NumberOfScrap: 'Number Of Scrap',
        NumberOfStacks: 'Number Of Stacks',
        NumberOfWasherDryerInstalls: 'Number Of Washer Dryer Installs',
        Charge100: 'Charge100',
        TotalHours2Man: 'Total Hours2Man',
        TotalHours3Man: 'Total Hours3Man',
        Charge50: 'Charge50',
        AddToCage: 'Add To Cage',
        DriverServices: 'Driver Services',
        ApprovedForBilling: 'Approved For Billing',
        ArchiveStatus: 'Archive Status',
        ArrivedAt: 'Arrived At',
        PlannedServices: 'Planned Services',
        Autobill: 'Autobill',
        AutoContractorBill: 'Auto Contractor Bill',
        AutoContractorBillEdited: 'Auto Contractor Bill Edited',
        Barcodes: 'Barcodes',
        BarcodesMatched: 'Barcodes Matched',
        BbCustomerEmailForSurvey: 'Bb Customer Email For Survey',
        BbCustomerSurveyComment1: 'Bb Customer Survey Comment1',
        BbCustomerSurveyComment2: 'Bb Customer Survey Comment2',
        BbGiftAmount: 'Bb Gift Amount',
        BbGiftCustomerEmail: 'Bb Gift Customer Email',
        BestBuyRobocallStatus: 'Best Buy Robocall Status',
        BestBuyRobocallTimestamp: 'Best Buy Robocall Timestamp',
        BestBuyRobocallSent: 'Best Buy Robocall Sent',
        BestBuySurveyQ1: 'Best Buy Survey Q1',
        BestBuySurveyQ2: 'Best Buy Survey Q2',
        BestBuySurveyQ3: 'Best Buy Survey Q3',
        BestBuySurveyQ4: 'Best Buy Survey Q4',
        BestBuySurveyQ5: 'Best Buy Survey Q5',
        BestBuySurveyQ6: 'Best Buy Survey Q6',
        BillingInfo: 'Billing Info',
        BillingValidated: 'Billing Validated',
        CalledCallCenter: 'Called Call Center',
        CityDistance: 'City Distance',
        Client: 'Client',
        CoastLocation: 'Coast Location',
        OrderStatus: 'Order Status',
        CompletedAt: 'Completed At',
        CompletedStops: 'Completed Stops',
        CancellationReason: 'Cancellation Reason',
        ContractorName: 'Contractor Name',
        ContractorPay: 'Contractor Pay',
        ContractorPayNotes: 'Contractor Pay Notes',
        CustomerComment: 'Customer Comment',
        CustomerName: 'Customer Name',
        CustomerRating: 'Customer Rating',
        CustomerSignature: 'Customer Signature',
        Deductions: 'Deductions',
        DeliveryAssignee: 'Delivery Assignee',
        DispatchClosed: 'Dispatch Closed',
        DispatchComments: 'Dispatch Comments',
        DispatchLog: 'Dispatch Log',
        DistributionZone: 'Distribution Zone',
        Driver: 'Driver',
        DriverAttachments: 'Driver Attachments',
        DriverNotes: 'Driver Notes',
        DriverTrackingLink: 'Driver Tracking Link',
        DropoffAddress: 'Dropoff Address',
        DropoffApt: 'Dropoff Apt',
        DropoffCityTown: 'Dropoff City Town',
        DropoffMapUrl: 'Dropoff Google Map Url',
        DropoffPostalCode: 'Dropoff Postal Code',
        DropoffStDr: 'Dropoff St Dr',
        DropoffUnit: 'Dropoff Unit',
        Email: 'Email',
        EndWindow: 'End Window',
        EtaLink: 'Eta Link',
        NumberOfExchanges: 'Number Of Exchanges',
        ExtraAttachments: 'Extra Attachments',
        GeoLocation: 'Geo Location',
        HourlyWork: 'Hourly Work',
        Invoice: 'Invoice',
        CoastInvType: 'Coast Inv Type',
        IsMetroCubeOrigin: 'Is Metro Cube Origin',
        KmDriven: 'Km Driven',
        LastArchived: 'Last Archived',
        LastAutobilled: 'Last Autobilled',
        LastMetroUpdate: 'Last Metro Update',
        LastTookanSync: 'Last Tookan Sync',
        Latitude: 'Latitude',
        Liabilities: 'Liabilities',
        LinkedOrder: 'Linked Order',
        LocationAccuracy: 'Location Accuracy',
        Longitude: 'Longitude',
        MetroNeedUpdate: 'Metro Need Update',
        NumberPastDoor: 'Number Past Door',
        NumberPriorToDoor: 'Number Prior To Door',
        NewOrderAttachments: 'New Order Attachments',
        NoCharge: 'No Charge',
        OpsBilling: 'Ops Billing',
        OpsMargin: 'Ops Margin',
        OutOfTown: 'Out Of Town',
        OverridePickupApt: 'Override Pickup Apt',
        OverridePickupCityTown: 'Override Pickup City Town',
        OverridePickupPostalCode: 'Override Pickup Postal Code',
        OverridePickupStDr: 'Override Pickup St Dr',
        OverridePickupUnit: 'Override Pickup Unit',
        Phone1: 'Phone1',
        Phone2: 'Phone2',
        ClientSubUnit: 'Client Sub Unit',
        PickupAddress: 'Pickup Address',
        PickupApt: 'Pickup Apt',
        PickupByLinehaul: 'Pickup By Linehaul',
        PickupCityTown: 'Pickup City Town',
        PickupMapUrl: 'Pickup Google Map Url',
        PickupPostalCode: 'Pickup Postal Code',
        PickupStDr: 'Pickup St Dr',
        PickupUnit: 'Pickup Unit',
        OrderType: 'Order Type',
        PickupCityDistance: 'Pickup City Distance',
        PickupGeoLocation: 'Pickup Geo Location',
        PickupLatitude: 'Pickup Latitude',
        PickupLocationAccuracy: 'Pickup Location Accuracy',
        PickupLongitude: 'Pickup Longitude',
        PlannedStops: 'Planned Stops',
        Pod: 'Pod',
        ProductCount: 'Product Count',
        ProductJson: 'Product Json',
        Products: 'Products',
        ProductsArrival: 'Products Arrival',
        PushTime: 'Push Time',
        DriverPushStatus: 'Driver Push Status',
        ReceivedInWh: 'Received In Wh',
        Region: 'Region',
        RequestedDeliveryWindow: 'Requested Delivery Window',
        ScannedBarcodes: 'Scanned Barcodes',
        ServiceTime: 'Service Time',
        ShippedOut: 'Shipped Out',
        Skus: 'Skus',
        StairsOver3rdFloor: 'Stairs Over3rd Floor',
        StartWindow: 'Start Window',
        StartedAt: 'Started At',
        OpsStatuses: 'Ops Statuses',
        Stem: 'Stem',
        StemKms: 'Stem Kms',
        Store: 'Store',
        Swamper: 'Swamper',
        SwamperEnd: 'Swamper End',
        SwamperStart: 'Swamper Start',
        TextMsgLogs: 'Text Msg Logs',
        TimeOffset: 'Time Offset',
        TookanStatus: 'Tookan Status',
        TookanId: 'Tookan Id',
        TotalTimeFromStart: 'Total Time From Start',
        Vehicle: 'Vehicle',
        UniqueOrderId: 'Unique Order Id',
        User: 'User',
        VaultId: 'Vault Id',
        Volume: 'Volume',
        WhirlpoolLoadId: 'Whirlpool Load Id',
        AutoBillEdited: 'Auto Bill Edited',
        AutoBillComments: 'Auto Bill Comments',
        ContractorBillingValidated: 'Contractor Billing Validated',
        FileUploadsDictionary: 'File Uploads Dictionary',
        _CreatedTimestamp: 'Created Timestamp',
        _UpdatedTimestamp: 'Updated Timestamp',
        _ImportedTimestamp: 'Imported Timestamp',
        _Operation: 'Operation',
    },
    portal: {
        'Main Portal': 'Main Portal',
        'TV Portal': 'TV Portal',
        'Same Day Portal': 'Same Day Portal',
    },
    contactless: {
        'Type and select zone to map clients': 'Type and select zone to map clients',
        'not exist': 'not exist',
        'Currently Mapped Clients': 'Currently Mapped Clients',
        Updated: 'Updated contactless delivery settings for {0}',
    },
    clientSettings: {
        'Create a new client': 'Create a new client',
        activate: 'Activate',
        deactivate: 'Deactivate',
        activated: 'activated',
        deactivated: 'deactivated',
        'client name is required': 'client name is required',
        'Include in Inventory': 'Include in Inventory',
        'Client Name': 'Client Name',
        'Successfully created': 'Successfully created',
        'Client already exists': 'Client already exists',
        'No region found': 'No region found',
        'Region is required': 'Region is required',
        'Type to search region': 'Type to search region',
        Inventory: 'Inventory',
        'Reverse Logistics': 'Reverse Logistics',
        'Include in Reverse Logistics': 'Include in Reverse Logistics',
        'Send Survey': 'Send Survey',
        Yes: 'Yes',
        No: 'No',
        'Billing Code': 'Billing Code',
        'Default address': 'Default address',
        'Save changes': 'Save changes',
        'Upload Survey Logo': 'Upload Survey Logo',
        'Remove Survey Logo': 'Remove Survey Logo',
    },
    ikeaDriverSetting: {
        'Select type to proceed': 'Select type to proceed',
        loading: 'loading...',
        drivers: 'drivers',
        contractors: 'contractors',
        'Create New': 'Create New',
        Update: 'Update',
        Delete: 'Delete',
        Region: 'Region',
        ID: 'ID',
        Name: 'Name',
        Company: 'Company',
        'Company Name': 'Company Name',
        Active: 'Active',
        Submit: 'Submit',
        Cancel: 'Cancel',
        'successfully updated': 'successfully updated',
        'successfully removed': 'successfully removed',
        'No options matching the keyword': 'No options matching the keyword',
        Select: 'Select',
    },
    dateRegion: {
        'dateField': 'Select Date Field',
        'dateRange': 'Select Date Range',
        'Records': 'Records',
        'firebase10Limit': 'Select up to 10 or all',
        'unassignedOrder2000Info': '01/01/2000',
        modeToggle: {
            moreOptions: 'Check back for more options'
        }
    },
    user: {
        deleteUserSuccessMsg: 'User was removed successfully.',
        removUserDialogTitle: 'Remove User',
        removUserDialogMsg: `You are about to delete  {{email}}. Are you sure?`,
    },
    generics: {
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        cancel: 'Cancel',
        save: 'Save',
        close: 'Close',
        delete: 'Delete',
        edit: 'Edit',
        add: 'Add',
        remove: 'Remove',
        update: 'Update',
        create: 'Create',
        search: 'Search',
        filter: 'Filter',
        volume: "Volume",
        weight: "Weight",
    },
    productDetails: {
        shyftCodeTooltip: 'Shyftbase generated barcode',
        Barcode: 'Barcode',
        Shyftcode: 'Shyftcode',
        BolNumber: 'Latest BOL',
        Comment: 'Comment',
        Mfr: 'Manufacturer',
        Model: 'Model',
        OriginHub: 'OriginHub',
        Product: 'Product',
        Quantity: 'Quantity',
        Serial: 'Serial',
        Sku: 'Sku',
        Status: 'Status',
        Volume: 'Volume',
        Weight: 'Weight',
        Id: 'Id',
        PoNumber: 'PO Number',
        PickupByLinehaul: 'Picked UP By Linehaul',
        BolHistory: 'BOL History',
        ShippingDate: 'Shipping Date',
        BolCreatedBy: 'Created By',
        BolOriginHub: 'Origin Hub',
        BolDestinationHub: 'Destination Hub',
    },
    orderTable: {
        updateNotification: {
            info: 'updated following orders',
            warning: 'Please re-select the order if you were about to edit one of them via edit window or quick action.'
        }
    }
}