import React, { useReducer, useMemo, useContext } from 'react'

// Reducer
import { UserReducer } from './modules/User/userReducer'
import { OrderReducer } from './modules/Order/orderReducer'
import { TableReducer } from './modules/Table/tableReducer'
// Context
import { GlobalContext } from './globalContext'
import { UserContext } from './modules/User/userContext'
import { OrderContext } from './modules/Order/orderContext'
import { TableContext } from './modules/Table/tableContext'
// Action
import { UserAction } from './modules/User/userActions'
import { ReportsAction } from './modules/Reports/reportsActions'
import { OrderAction } from './modules/Order/orderAction'
import { TableAction } from './modules/Table/tableAction'

import { UIReducer } from './modules/UI/uiReducer'
import { UIContext } from './modules/UI/uiContext'
import { UIAction } from './modules/UI/uiAction'

const GlobalState = ({ children }: any) => {
    const [userState, userDispatch] = useReducer(UserReducer, useContext(UserContext))
    const [orderState, orderDispatch] = useReducer(OrderReducer, useContext(OrderContext))
    const [tableState, tableDispatch] = useReducer(TableReducer, useContext(TableContext))
    const [uiState, uiDispatch] = useReducer(UIReducer, useContext(UIContext))

    const providerValue: any = useMemo(
        () => ({
            user: {
                user: userState.user,
                isDriver: userState.isDriver,
                isVendor: userState.isVendor,
                isSBCustomer: userState.isSBCustomer,
                role: userState.role,
                firstName: userState.firstName,
                lastName: userState.lastName,
                phone: userState.phone,
                email: userState.email,
                vendorProfileDetails: userState.vendorProfileDetails,
                driverProfileDetails: userState.driverProfileDetails,
                updateUser: (value: any) => UserAction.updateUser(userDispatch, value),
                getIdToken: () => UserAction.getIdToken(),
            },
            reports: {
                generateCSV: (value: any) => ReportsAction.generateCSV(value),
                generateCSVFromLive: (value: any) => ReportsAction.generateCSVFromLive(value),
                generateCSVForDataExtract: (value: any) => ReportsAction.generateCSVForDataExtract(value),
                generateCSVLiveWithComments: (value: any) => ReportsAction.generateCSVLiveWithComments(value),
            },
            orders: {
                orders: orderState.orders,
                selectedOrder: orderState.selectedOrder,
                chatHistory: orderState.chatHistory,
                addOrders: (value: any) => OrderAction.addOrders(orderDispatch, value),
                updateOrders: (value: any) => OrderAction.updateOrders(orderDispatch, value),
                cancelOrder: (value: any) => OrderAction.cancelOrder(orderDispatch, value),
                deleteOrders: (value: any) => OrderAction.deleteOrders(orderDispatch, value),
                emptyOrders: () => OrderAction.emptyOrders(orderDispatch),
                emptyChatHistory: () => OrderAction.emptyChatHistory(orderDispatch),
                selectOrder: (value: any) => OrderAction.selectOrder(orderDispatch, value),
                pushMessage: (value: any) => OrderAction.pushMessage(orderDispatch, value),
            },
            table: {
                countForFetch: tableState.countForFetch,
                addCountForFetch: () => TableAction.addCountForFetch(tableDispatch),
                rateTables: tableState.rateTables,
                selectedTableOrder: tableState.selectedTableOrder,
                selectedDate: localStorage.getItem('selectedDate')
                    ? JSON.parse(localStorage.getItem('selectedDate') || '')
                    : { from: null, to: null } || { from: null, to: null },
                selectedRegion:
                    (localStorage.getItem('selectedRegion')
                        ? JSON.parse(localStorage.getItem('selectedRegion') || '')
                        : []) || [],
                selectedClient:
                    (localStorage.getItem('selectedClient')
                        ? JSON.parse(localStorage.getItem('selectedClient') || '')
                        : []) || [],
                allStems: tableState.allStems,
                actions: tableState.actions,
                role: tableState.roles,
                contractorsList: tableState.contractorsList,
                rateConditions: tableState.rateConditions,
                selectedView: tableState.selectedView,
                setSelectedView: (value: any) => TableAction.setSelectedView(tableDispatch, value),
                updateRateConditions: (value: any) => TableAction.updateRateConditions(tableDispatch, value),
                updateRateTables: (value: any) => TableAction.updateRateTables(tableDispatch, value),
                updateAllStems: (value: any) => TableAction.updateAllStems(tableDispatch, value),
                selectTableOrder: (value: any) => TableAction.selectTableOrder(tableDispatch, value),
                selectTableSelectedDate: (value: any) => TableAction.selectTableSelectedDate(tableDispatch, value),
                selectTableSelectedRegion: (value: any) => TableAction.selectTableSelectedRegion(tableDispatch, value),
                selectTableSelectedClient: (value: any) => TableAction.selectTableSelectedClient(tableDispatch, value),
                newView: (value: any) => TableAction.newView(value),
                updateView: (value: any) => TableAction.updateView(value),
                deleteView: (value: any) => TableAction.deleteView(value),
                updateActions: (value: any) => TableAction.updateActions(tableDispatch, value),
                updateRole: (value: any) => TableAction.updateRole(tableDispatch, value),
                updateContractorsList: (value: any) => TableAction.updateContractorsList(tableDispatch, value)
            },
            ui: {
                mode: uiState.mode,
                updateUIMode: (value: string) => UIAction.updateUIMode(uiDispatch, value),
            },
        }),
        [userState.isDriver,
        userState.isVendor,
        userState.role,
        userState.firstName,
        userState.lastName,
        userState.phone,
        userState.email,
        userState.user,
        userState.isSBCustomer,
        userState.vendorProfileDetails,
        userState.driverProfileDetails,
        orderState.orders,
        orderState.selectedOrder,
        orderState.chatHistory,
        tableState.selectedTableOrder,
        tableState.rateTables,
        tableState.allStems,
        tableState.actions,
        tableState.contractorsList,
        tableState.rateConditions,
        tableState.selectedView,
        tableState.countForFetch,
        uiState.mode,
        tableState.roles]
    )

    return <GlobalContext.Provider value={providerValue}>{children}</GlobalContext.Provider>
}

export default GlobalState
