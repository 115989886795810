import { createContext } from 'react'
/* TODO: CONNECT TO USER TYPE */
export const UserContext = createContext({
    apiKeys: null,
    countryCode: 1,
    email: '',
    firstName: '',
    isDriver: false,
    isSBCustomer: false,
    isVendor: false,
    lastName: '',
    phone: '',
    role: '',
    stores: [''],
    driverProfileDetails: '',
    vendorProfileDetails: '',
    visibilityThreshold: '',
    regions: [''],
})
