import { UPDATE_AUTH_STATE, SET_DID_SET_INTERVAL_RUN, UPDATE_PORTAL } from './userActions.types'
import { auth } from '../../../../services/firebase/firebaseConfig'
let intervalCheck: any
export const UserAction = {
    setDidSetIntervalRun: (dispatch: any, value: any) => {
        dispatch({
            type: SET_DID_SET_INTERVAL_RUN,
            payload: value,
        })
    },
    updatePortal: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_PORTAL,
            payload: value,
        })
    },
    updateUser: (dispatch: any, value: any) => {
        const user = value
        let apiKeys = null
        let countryCode = 1
        let email = ''
        let firstName = ''
        let isDriver = false
        let isSBCustomer = false
        let isVendor = false
        let lastName = ''
        let phone = ''
        let role = ''
        let stores = ['']
        let driverProfileDetails = ''
        let vendorProfileDetails = ''
        let visibilityThreshold = ''
        let regions = ['']
        const defaultUserPayload = {
            user: null,
            apiKeys,
            countryCode,
            email,
            firstName,
            isDriver,
            isSBCustomer,
            isVendor,
            lastName,
            phone,
            role,
            stores,
            driverProfileDetails,
            vendorProfileDetails,
            visibilityThreshold,
            regions,
        }
        if (!user) {
            dispatch({
                type: UPDATE_AUTH_STATE,
                payload: defaultUserPayload,
            })
            return
        }
        user.getIdTokenResult(true).then((idTokenResult: any) => {
            const claims = idTokenResult && idTokenResult.claims
            if (!claims) {
                dispatch({
                    type: UPDATE_AUTH_STATE,
                    payload: defaultUserPayload,
                })
            }
            apiKeys = claims.apiKeys
            countryCode = claims.countryCode
            email = claims.email
            firstName = claims.firstName
            isDriver = claims.isDriver
            isSBCustomer = claims.isSBCustomer
            isVendor = claims.isVendor
            lastName = claims.lastName
            phone = claims.phone
            role = claims.role
            stores = claims.stores
            driverProfileDetails = claims.driverProfileDetails
            vendorProfileDetails = claims.vendorProfileDetails
            visibilityThreshold = claims.visibilityThreshold
            regions = claims.regions
            dispatch({
                type: UPDATE_AUTH_STATE,
                payload: {
                    user,
                    apiKeys,
                    countryCode,
                    email,
                    firstName,
                    isDriver,
                    isSBCustomer,
                    isVendor,
                    lastName,
                    phone,
                    role,
                    stores,
                    driverProfileDetails,
                    vendorProfileDetails,
                    visibilityThreshold,
                    regions,
                },
            })
        })
    },
    getIdToken: async () => await auth.currentUser?.getIdToken(true),
    checkUserActivity: (dispatch: any, currentState: any, value: any) => {
        // const { lastLoginTime, expiry } = value
        if (process.env.REACT_APP_CLIENT === 'BESTBUY') {
            if (!currentState.didSetIntervalRun) {
                dispatch({
                    type: SET_DID_SET_INTERVAL_RUN,
                    payload: true,
                })
                /* if lastActiveTime was not valid, then compare Date.now to last login time */
                /* if lastActiveTime was valid, then compare Date.now to lastActiveTime  */
                // const compareActiveTime = (lastLoginTime: number, expiry: number) => {
                //   const lastActiveTime = (localStorage.getItem('lastActiveTime') && parseInt(localStorage.getItem('lastActiveTime') as string)) || null
                //   let isUserSessionValid: string
                //   if (!lastActiveTime) {
                //     isUserSessionValid = expiry > (Date.now() - lastLoginTime) ? "valid" : "invalid"
                //   } else {
                //     isUserSessionValid = expiry > (Date.now() - lastActiveTime) ? "valid" : "invalid"
                //   }
                //   if (isUserSessionValid === 'invalid') {
                //     // commit('setDidSetIntervalRun', false)
                //     dispatch({
                //         type: SET_DID_SET_INTERVAL_RUN,
                //         payload: false
                //     })
                //     // commit('resetStorageAndInterval')
                //     // firebase.auth.signOut()
                //   }
                // }
                //     intervalCheck = setInterval(() => {
                //       compareActiveTime(lastLoginTime, expiry)
                //     }, 300000) // check every 5 min 300000
            }
        }
    },
}
