import React from 'react'
import Routes from './core/routes'
import { createRoot } from 'react-dom/client';

import '@shopify/polaris/build/esm/styles.css'
import 'react-calendar/dist/Calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import './services/firebase/firebaseConfig'
import './assets/theme/main_theme.scss'
import './assets/theme/main_layout.scss'

import { Provider } from "react-redux";
import { store } from './core/redux/store'

// After react 18.0
function AppWithCallbackAfterRender() {
    return (<Provider store={store}>
        <Routes />
    </Provider>)
}

// After using React 18.0
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);