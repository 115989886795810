import { Icon } from '@shopify/polaris'
import {
    ChannelsMajor, LanguageMinor, LogOutMinor,
    ProductsMajor, ReportsMajor, SettingsMajor, ShipmentMajor, ToolsMajor, TransferWithinShopifyMajor
} from '@shopify/polaris-icons'
import { signOut } from 'firebase/auth'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import { GlobalContext } from '../../core/context/globalContext'
import { auth } from '../../services/firebase/firebaseConfig'

const NavStyles = {
    background: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2Farno-senoner-yqu6tJkSQ_k-unsplash.jpg?alt=media&token=34c71551-b44b-4d29-b143-95f3549e483a")',
        filter: 'blur(10px)',
        WebkitFilter: 'blur(10px)',
    } as React.CSSProperties,
}

const Nav = () => {
    useHotkeys('ctrl+shift+1', () => goToPage('/dateRegion'))
    useHotkeys('ctrl+shift+2', () => goToPage('/routes'))
    useHotkeys('ctrl+shift+3', () => goToPage('/mapFleet'))
    useHotkeys('ctrl+shift+4', () => goToPage('/reports'))
    useHotkeys('ctrl+shift+5', () => goToPage('/liveConnect'))
    useHotkeys('ctrl+shift+6', () => goToPage('/admin'))
    useHotkeys('ctrl+shift+l', () => setIsActive(true))

    const [isActive, setIsActive] = useState(false)
    const [isModeActive, setIsModeActive] = useState(false)
    const [languageMessage, showLanguageMessage] = useState('')
    const [openSnackbar] = useSnackbar()
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const {
        user: { updateUser },
        ui: { updateUIMode, mode },
    } = useContext(GlobalContext)
    const handleUIMode = useCallback(async (value) => {
        updateUIMode(value)
        openSnackbar(t('nav.drawer.handleUiModeMsg', { mode: value }))
    }, [openSnackbar, t, updateUIMode])
    const onChangeLanguage = useCallback(
        (lang: string) => {
            i18n.changeLanguage(lang)
        },
        [i18n]
    )
    const logout = useCallback(async () => {
        signOut(auth).then((res) => {
            updateUser(null)
        })
    }, [updateUser])
    const goToPage = useCallback((path) => history.push(path), [history])
    const MainNavigations = useMemo(() => {
        return mode === 'Services'? [
            { path: '/dateRegion', label: 'nav.drawer.orders', onClick: () => goToPage('/dateRegion'), icon: ProductsMajor },
            { path: '/analytics', label: 'nav.drawer.report', onClick: () => goToPage('/analytics'), icon: ReportsMajor },
            { path: '/mapFleet', label: 'nav.drawer.mapFleet', onClick: () => goToPage('/mapFleet'), icon: ChannelsMajor },
        ] 
        : [
            { path: '/dateRegion', label: 'nav.drawer.orders', onClick: () => goToPage('/dateRegion'), icon: ProductsMajor },
            { path: '/routes', label: 'nav.drawer.routes', onClick: () => goToPage('/routes'), icon: ShipmentMajor },
            { path: '/mapFleet', label: 'nav.drawer.mapFleet', onClick: () => goToPage('/mapFleet'), icon: ChannelsMajor },
            { path: '/analytics', label: 'nav.drawer.report', onClick: () => goToPage('/analytics'), icon: ReportsMajor },
            // { path: '/liveConnect', label: 'nav.drawer.liveConnect', onClick: () => goToPage('/liveConnect'), icon: LiveViewMajor },
        ]
    }, [goToPage, logout])

    const handleChangeLang = useCallback((lang: string) => {
        onChangeLanguage(lang)
        setIsActive(false)
    }, [onChangeLanguage])

    const renderNavItem = useCallback((item, index) => {
        return (
            <div
                className={`shyftbase_main_nav_item ${
                    (window.location.href.includes("records") ? "/dateRegion" : window.location.href).includes(item.path)
                    ? 'selected' : ''}`}
                onClick={item.onClick}
                key={`nav_${item.path}_${index}`}
            >
                <Icon source={item.icon} />
                <p>{t(item.label)}</p>
            </div>
        )
    }, [])
    return (
        <>
            <div className={`modal ${isModeActive ? "is-active" : ""}`}>
                <div style={NavStyles.background} className="modal-background has-background-dark" ></div>
                <div className="is-flex is-flex-direction-column justify-content-center " style={{ minWidth: 300 }}>
                    <div
                        className={`m-1 ${mode === 'Logistics' ? 'selected' : ''}`}
                        onClick={() => { handleUIMode('Logistics'); setIsModeActive(false) }}
                    >
                        <button className={mode === 'Logistics' ? 'has-text-white button is-fullwidth has-background-success' : 'button is-fullwidth'}>{t('nav.drawer.logistics')}</button>
                    </div>
                    <div
                        className={`m-1 ${mode === 'Installations' ? 'selected' : ''}`}
                        onClick={() => { handleUIMode('Installations'); setIsModeActive(false) }}
                    >
                        <button className={mode === 'Installations' ? 'has-text-white button is-fullwidth has-background-success' : 'button is-fullwidth'}>{t('nav.drawer.installations')}</button>
                    </div>
                    <div
                        className={`m-1 ${mode === 'Services' ? 'selected' : ''}`}
                        onClick={() => { handleUIMode('Services'); setIsModeActive(false) }}
                    >
                        <button className={mode === 'Services' ? 'has-text-white button is-fullwidth has-background-success' : 'button is-fullwidth'}>{t('nav.drawer.services')}</button>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setIsModeActive(false)}></button>
            </div>
            <div className={`modal ${isActive ? "is-active" : ""}`}>
                <div style={NavStyles.background} className="modal-background has-background-dark" ></div>
                <div className="is-flex is-flex-direction-column justify-content-center " style={{ maxWidth: 300, minWidth: 300 }}>
                    <button className='my-1 button is-fullwidth' onClick={() => { handleChangeLang('en'), showLanguageMessage('') }}>English</button>
                    <button className='my-1 button is-fullwidth' onClick={() => showLanguageMessage('fr')}>Français</button>
                    <button className='my-1 button is-fullwidth' onClick={() => showLanguageMessage('de')}>Deutsch</button>
                    {languageMessage === 'fr' && <div className='my-2 button is-fullwidth is-7  title has-text-center'>Le portail est en cours de traduction en français!</div>}
                    {languageMessage === 'de' && <div className='my-2 button is-fullwidth is-7  title has-text-center'>Das portal wird derzeit ins Deutsche übersetzt!</div>}
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setIsActive(false)}></button>
            </div>
            <nav className="shyftbase_nav" role="navigation" aria-label="main navigation">
                <div className="shyftbase_main_nav">
                    <div className="shyftbase_main_nav_item logo">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/ShyftBase-Logo-A.png?alt=media&token=6714e05a-a82e-42d3-88ec-14152a00fdaa"
                            alt="Shyftbase Logo"
                            width={35}
                        />
                    </div>
                    {MainNavigations.map(renderNavItem)}
                    <div className={`shyftbase_main_nav_item toggle`}></div>
                    <div className={`shyftbase_main_nav_item toggle`}></div>
                    <div className={`shyftbase_main_nav_item ${window.location.href.includes('admin') ? 'selected' : ''}`} onClick={() => goToPage('/admin')} >
                        <Icon source={SettingsMajor} />
                        <p>{t('nav.drawer.admin')}</p>
                    </div>
                    <div className={`shyftbase_main_nav_item toggle}`} onClick={() => setIsActive(true)} >
                        <Icon source={LanguageMinor} />
                        <p style={{ fontSize: 8 }}>{t('nav.drawer.language')}</p>
                    </div>
                    <div
                        className={`shyftbase_main_nav_item toggle`} onClick={() => setIsModeActive(true)}
                    >
                        <Icon source={mode === 'Installations' ? ToolsMajor : TransferWithinShopifyMajor} />
                        <p>{t('nav.drawer.mode')}</p>
                    </div>
                    <div className={`shyftbase_main_nav_item toggle}`} onClick={logout} >
                        <Icon source={LogOutMinor} />
                        <p>{t('nav.drawer.logout')}</p>
                    </div>
                </div>
            </nav>
        </>
    )
}
export default React.memo(Nav)
