import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import '../i18n/i18n'
import GlobalState from './context/globalState'
import MainRoutes from './mainRoutes'
import { fetchBillingData, fetchData } from './redux/store'

const Routes = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        /* fetching prerequisites : data (distributionzones, vendors, orderstatusesref, opsstatusesref, fieldsnapshot, ordertypesref) & billing data */
        dispatch(fetchBillingData())
        dispatch(fetchData())
    }, [])
    return (
        <GlobalState>
            <Router>
                <Switch>
                    <SnackbarProvider>
                        {/* MAIN PORTAL PAGES */}
                        <Route exact path="/" component={MainRoutes} />
                        <Route exact path="/login" component={MainRoutes} />
                        <Route exact path="/forgotPassword" component={MainRoutes} />
                        <Route exact path="/changePassword" component={MainRoutes} />
                        <Route exact path="/mainContainer" component={MainRoutes} />
                        <Route exact path="/liveConnect" component={MainRoutes} />
                        <Route exact path="/records" component={MainRoutes} />
                        <Route exact path="/dateRegion" component={MainRoutes} />
                        <Route exact path="/mapFleet" component={MainRoutes} />
                        <Route exact path="/routes" component={MainRoutes} />
                        <Route exact path="/analytics" component={MainRoutes} />

                        {/* PUBLIC PAGES */}
                        <Route exact path="/trackorder" component={MainRoutes} />
                        <Route exact path="/trackorder/:trackId" component={MainRoutes} />

                        {/* ADMIN PAGES */}
                        <Route exact path="/admin" component={MainRoutes} />
                        <Route exact path="/admin/role" component={MainRoutes} />
                        <Route exact path="/admin/userRole" component={MainRoutes} />
                        <Route exact path="/admin/vendor" component={MainRoutes} />
                        <Route exact path="/admin/contractor" component={MainRoutes} />
                        <Route exact path="/admin/distributionZone" component={MainRoutes} />
                        <Route exact path="/admin/reports" component={MainRoutes} />
                        <Route exact path="/admin/notifications" component={MainRoutes} />
                        <Route exact path="/admin/calendarSettings" component={MainRoutes} />
                        <Route exact path="/admin/billing" component={MainRoutes} />
                        <Route exact path="/admin/billing/ratetable" component={MainRoutes} />
                        <Route exact path="/admin/billing/zones" component={MainRoutes} />
                        <Route exact path="/admin/billing/fscrates" component={MainRoutes} />
                        <Route exact path="/admin/billing/weeklyfscrates" component={MainRoutes} />
                        <Route exact path="/admin/billing/services" component={MainRoutes} />
                        <Route exact path="/admin/billing/substatus" component={MainRoutes} />
                        <Route exact path="/admin/billing/conditions" component={MainRoutes} />
                        <Route exact path="/admin/billing/tiersEscrow" component={MainRoutes} />
                        <Route exact path="/admin/billing/ikeapostalcode" component={MainRoutes} />

                    </SnackbarProvider>
                </Switch>
            </Router>
        </GlobalState>
    )
}

export default React.memo(Routes)

