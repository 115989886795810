export default {
    language: 'Langue',
    'Coming Soon': 'Bientôt disponible!',
    login: {
        selectStore: 'Sélectionner un magasin',
        selectRegion: 'Sélectionner une région',
        selectRole: 'Sélectionner votre rôle',
        placeholder: {
            email: 'Adresse courriel',
            password: 'Mot de passe',
            verification: "S'il vous plaît entrez le code de vérification",
        },
        rules: {
            characters: '8 caractères',
            number: 'Contient des chiffres',
            uppercase: 'Contient des majuscules',
            specialCharacters: 'Au moins un caractère spécial',
        },
        messages: {
            verification: "Vous recevrez un code d'activation sur votre téléphone",
            captchaExpired: 'La session a expiré. Veuillez réessayer!',
            redirectingToPortal: "Vous serez redirigé automatiquement vers la page d'accueil.",
            emailVerification: 'Nous vous avons envoye un email pour vérifier votre adresse courriel.',
            invalidAccount: 'Invalid Account (compte non valide)',
            unsupportedAccess: 'Invalid Account (compte non valide)',
            enterVerficationCode: "S'il vous plaît entrez le code de vérification",
            somethingWentWrong: 'Veuillez réessayer plus tard. ',
            standardRate: '',
            firstTimeLogin: 'Avez-vous un compte?',
            goBackToLogin: 'Se connecter',
            goBackToSignup: 'Créer un compte',
        },
        button: {
            submit: 'Envoyer',
            login: 'Se connecter',
            signup: 'Créer un compte',
        },
        errorCode: {
            'auth/wrong-password': 'Le mot de passe entré est incorrect.',
            'auth/user-not-found': 'Votre email n’est associé à aucun compte Shyftbase',
            'auth/invalid-email': 'Votre email n’est associé à aucun compte Shyftbase',
        },
        twofa: {
            secureAccess: 'Portail Sécurisé',
            sendBtn: "Envoyer le code d'activation",
        },
    },
    nav: {
        addOrder: 'Nouvelle Commande',
        orders: 'Ordres',
        search: 'Recherche',
        logout: 'Déconnexion',
        drawer: {
            orders: 'Commande',
            routes: 'Routes',
            mapFleet: 'Hubs & Fleet',
            invoice: 'Facturation',
            billingModule: 'Billing Module',
            home: 'Accueil',
            search: 'Recherche ',
            liveConnect: 'Connexion en Direct',
            newOrder: 'Nouvelle Commande',
            history: 'Historique',
            report: 'Analytics',
            admin: 'Administrateur',
            logout: 'Déconnexion ',
            reverseLogistics: 'Reverse Logistics',
            Installations: 'Installer',
            Logistics: 'Livraison',
            Mode: 'Mode',
        },
    },
    search: {
        title: {
            title: 'Vous pouvez rechercher uniquement les commandes approuvées.',
            subtitle1: 'Pour rechercher des commandes en attente, visitez',
            subtitle2: 'les commandes en attente',
            msg: '* Using date range is recommended for better performance and efficiency',
        },
        label: {
            or: 'Ou',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            phoneNumber: 'Numéro de téléphone',
            address: 'Adresse',
            invoice: 'Tapez le numéro de facture et appuyez sur Entrée',
            from: 'A partir de',
            to: "Jusqu'à",
            deliveryDate: 'Date de Livraison',
            selectStatus: 'Statut de Livraison',
            // new
            productName: 'f Product Name',
            barcode: 'f Barcode',
            whid: 'f WHID',
            sku: 'SKU',
            invoiceInput: 'Invoice',
            poNumber: 'PO Number',
            'Scanned In': 'Scanned In',
            'Scanned Out': 'Scanned Out',
        },
        button: {
            reset: 'RÉESSAYER',
            search: 'RECHERCHE',
        },
        messages: {
            recordsFound: 'enregistrement(s) trouvé(s)',
            noRecordsFound: "Aucun enregistrement n'a été trouvé",
            noInputErr: 'Please type at least one keyword to search orders.',
            otherErr: 'Something went wrong. Please contact Shyftbase.',
        },
        'Customer Info': 'Les Clients',
        'Order Details': 'Détails des Commandes',
        Statuses: 'Statuts des Commandes',
        Products: 'Les produits',
        'Reverse Logistics': 'Logistique Inversée',
        'Client Search': 'Client',
        'Zone Search': 'Zone',
        'Minimize Tags': 'Minimize Tags',
        'Show All Tags': 'Show All Tags',
        'Type to show or hide fields': 'Type to show or hide fields',
        'click to show': 'click to show',
        'click to hide': 'click to hide',
        selected: 'selected',
        'Search For Anything': 'Search For Anything',
        'Search Input Placeholder': 'Example: Order #, Customer Name, Vendor, PO #, Product, Barcode, WHID, SKU',
    },
    liveConnect: {
        orderType: {
            needAttention: 'ATTENTION',
            pending: 'EN ATTENTE',
            approved: 'APPROUVÉ',
            cancelled: 'ANNULÉ',
            completed: 'APPROUVÉ',
            customerRequest: 'CUSTOMER REQUEST',
        },
        region: 'Région',
        store: 'Magasin',
        invoice: 'Numéro de commande',
        filter: 'filtre',
        loading: 'Chargement...',
    },
    liveOrdersContainer: {
        tooltip: {
            hideStops: '',
            showStops: '',
        },
    },
    liveOrder: {
        orderType: {
            Delivery: 'Livraison',
            Pickup: 'Ramassage',
            'Service Call': 'Appel de Service',
            'Store-Stop': 'Ramassage',
        },
        cancellationReason: {
            RESCHEDULE: 'REPROGRAMMER',
            'NO STOCK': 'PAS DE STOCK',
            'CANCELLED BY CUSTOMER': 'ANNULÉ PAR LE CLIENT',
            'ADDRESS NOT FOUND': 'ADRESSE NON TROUVÉE',
            'ADULT NOT PRESENT': 'ADULTE ABSCENT',
            'PREVIOUSLY DONE': 'PRÉCÉDEMMENT FAIT',
            'BOOKING ERROR': 'ERREUR DE RÉSERVATION',
            'CANCELLED BY STORE': 'ANNULÉ PAR LE MAGASIN',
            'CANCELLED TO RESCHEDULE': 'ANNULÉ POUR REPROGRAMMER',
            'CREATED - NO STOCK': 'CRÉÉ - PAS DE STOCK',
            'DELIVERY TEAM ILLNESS / INJURY': "MALADIE / BLESSURE DE L'ÉQUIPE DE LIVRAISON",
            'DUPLICATE ORDER': 'COMMANDE EN DOUBLE',
            'FLY BY - NO PRODUCT AVAILABLE': ' Fly-By - AUCUN PRODUIT DISPONIBLE ',
            'INCORRECT ADDRESS': 'ADRESSE INCORRECTE',
            'INCORRECT ADDRESS - NOT LOADED ON TRUCK': 'ADRESSE INCORRECTE - NON CHARGÉ SUR LE CAMION',
            'INSUFFICIENT MANPOWER': 'EFFECTIFS INSUFFISANTS',
            'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK': 'EFFECTIF INSUFFISANT - NON CHARGÉ SUR LE CAMION',
            'NAH - OUTSIDE WINDOW': 'NAH - FENÊTRE EXTÉRIEURE ',
            'NO STOCK - MID MILE DELAY': 'PAS DE STOCK - RETARD DE MI-MILLE',
            'NO STOCK LOCATED AT HUB': 'PAS DE STOCK SITUÉ AU CENTRE',
            'OVERSIZED PRODUCTS(S)': 'PRODUIT(S) SURDIMENSIONNÉ(S)',
            'PRODUCT NOT LOADED': 'PRODUIT NON CHARGÉ',
            'PROPERTY DAMAGE': 'DOMMAGES MATÉRIELS',
            'PROPERTY NOT ACCESSIBLE': 'PROPRIÉTÉ NON ACCESSIBLE',
            'REFUSED - CONCEALED DAMAGE': 'REFUSÉ - DOMMAGES DISSIMULÉS',
            'REFUSED - DRIVER DAMAGE': 'REFUSÉ - DOMMAGES AU CONDUCTEUR',
            'REFUSED - INCORRECT PRODUCT ORDERED': 'REFUSÉ - PRODUIT INCORRECT COMMANDÉ',
            'REFUSED - INCORRECT PRODUCT SHIPPED': 'REFUSÉ - PRODUIT INCORRECT EXPÉDIÉ',
            'REFUSED - PRODUCT DEFECTIVE': 'REFUSÉ - PRODUIT DÉFECTUEUX',
            'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK': 'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK',
            'RESCHEDULED BY CUSTOMER': 'REPROGRAMMÉ PAR LE CLIENT',
            'VEHICLE ISSUES': 'Lave- vaisselle - NON CHARGÉ SUR LE CAMION',
            'WEATHER - NOT LOADED ON TRUCK': 'MÉTÉO - NON CHARGÉ SUR LE CAMION',
            'WEATHER ISSUES': 'LES PROBLÈMES MÉTÉOROLOGIQUES',
        },
        orderStatus: {
            'Need Attention': 'Attention',
            Pending: 'En Attente',
            Cancelled: 'Annulé',
            Approved: 'Approuvé',
        },
    },
    orderEntry: {
        viewPendingOrder: 'voir la commande en attente',
        title: {
            create: 'Créer une nouvelle commande',
            update: 'Mise à jour de la commande',
        },
        subtitle: {
            zone: 'Zone : ',
            pickupDate: 'Date de ramassage',
            deliveryDate: 'Date de livraison',
            scheduledDate: 'Date',
            services: 'Les Services',
            products: 'Les produits',
        },
        placeholder: {
            placeOrderTakingTimeMessage: '',
            select: 'Veuillez sélectionner le type de commande',
            postalCode: 'Code Postal',
            selectReturn: 'Sélectionnez le type de renvoi',
            selectedDate: 'Date: ',
            additionalInfo: 'Informations complémentaires',
        },
        caution: {
            first: 'Pour un ',
            second: '"haulaway/recyclage"',
            third: " , veuillez sélectionner l'option ",
            fourth: ' "Appel de service" ',
            fifth: '',
        },
        button: {
            check: 'Vérifier les disponibilités',
            submit: 'Envoyer',
        },
        messages: {
            submitError:
                "Désolé, la réservation pour ce code postal n'est pas possible pour le moment ! Veuillez vérifier le code postal que vous avez saisi.Si vous pensez qu'il s'agit d'une erreur, veuillez demander l'ajout de ce code postal aux zones prises en charge en envoyant un courriel à hcustome@bestbuycanada.ca",
            selectActiveDate: "Veuillez sélectionner la date d'activation",
            saveProduct: 'Veuillez vous assurer que les produits sont sauvegardés!',
            saveService: 'Veuillez vous assurer que les services sont sauvegardés!',
            selectDate: 'Veuillez vous assurer que la date est sélectionnée',
            validInvoice: 'Veuillez vous assurer que la facture est valide!',
            validPhone: 'Veuillez vous assurer que le téléphone est valide!',
            validFirstName: 'Veuillez vous assurer que le prénom est valide!',
            validLastName: 'Veuillez vous assurer que le nom de famille est valide!',
            validStreet: 'Assurez-vous que la rue est valide!',
            validCity: 'Veuillez vous assurer que la ville est valide !',
            validUnit: "Assurez-vous que le numéro de l'appareil est valide !",
            orderAdded: 'La commande a été ajoutée à la liste des commandes en cours !',
            orderUpdated: 'La commande a été mise à jour avec succès ! ',
            somethingWentWrong: 'Désolé, il y a eu un problème. Veuillez fermer ce message et le soumettre à nouveau.',
            lanudryAndFridgeDoorMsg:
                "Veuillez confirmer que l'appareil à réparer est neuf ou acheté et livré par Metro To Home dans les 30 jours",
            isAccessoriesNew:
                "Tous les accessoires sont neufs ou d'occasion ? Cliquez sur 'Oui' si l'appareil est neuf, 'Non' si l'appareil est d'occasion",
            haulawayMsg:
                "Veuillez noter que vous avez sélectionné un service de transport qui enverra l'appareil au recyclage. Veuillez-vous assurer que le client désinstalle l'appareil et le prépare pour le ramassage avant l'arrivée de notre équipe",
            largeHawlawayMsg:
                "Vous avez sélectionné un plus grand nombre de services de recyclage que de produits. Veuillez revoir votre sélection. Vous ne pouvez sélectionner qu'un seul recyclage pour un seul produit",
            generateLargeQtyMessage:
                "Il ne s'agit pas d'une livraison à domicile de taille normale, en tant que telle, veuillez-vous assurer de suivre le processus de commande volumineuse sur ETK.",
            generateFourManMessage: 'Veuillez-vous assurer de fournir les détails, SKU et QTY dans les commentaires.',
            generateReturnMessage:
                'Retour à DC - Veuillez fournir des détails (RAISON DU RETOUR) dans les commentaires.',
            serviceConditionRequirement: "La condition de service n'est pas remplie",
            generateInvoiceAuthMessage: "Désolé, seule l'administration a la permission de créer 900 commandes",
            oversizeFridge: 'Veuillez indiquer les détails et la SKU/Quantité dans les commentaires',
            over90Days:
                'Vous avez choisi une date qui dépasse le seuil de 90 jours. Veuillez vous assurer que la date sélectionnée est correcte',
            usedAccessories:
                "Les accessoires d'installation doivent être neufs et non ouverts pour que notre livraison à domicile puisse compléter l'installation.",
            yes: 'OUI',
            no: 'NON',
        },
        options: {
            flyby: 'Fly By',
            serviceCall: 'Appel de service',
            pickup: 'Ramassage',
        },
    },
    serviceList: {
        service: 'Service',
        qty: 'QTY',
        remove: 'supprimer',
    },
    saveServiceForm: {
        selectService: 'Sélectionnez un service',
        saveService: 'Enregistrer les services',
        qty: 'Quantité',
        options: {
            fourMan: 'Livraison à 4 personnes - Préciser les détails dans les commentaires',
            LevelUnit: "Niveau de l'unité",
            MoveUnit: "Déplacer l'unité sur place",
            Pedestal: "Mise en place d'un piédestal",
            StackingLaundry: 'Empilage du linge',
            WasherConnection: 'Connexion de la machine à laver',
            DryerConnection: 'Connexion du sèche-linge',
            WasherandDryerConnection: 'Raccordement de la machine à laver et du sèche-linge',
            FridgeDoorSwing: 'Porte du réfrigérateur',
            OversizeFridge: 'Réfrigérateur surdimensionné (+26/350LB)',
            Haulaway: 'Recyclage',
            Doorstep: 'Seuil de porte',
        },
    },
    productList: {
        product: 'Produit',
        qty: 'QTY',
        sku: 'SKU',
        model: 'MODÈLE',
        remove: 'supprimer',
    },
    saveProductForm: {
        name: 'Nom du Produit',
        qty: 'Quantité ',
        sku: 'SKU',
        model: 'Modèle',
        save: 'Enregistrer les produits',
    },
    history: {
        loading: 'Chargement...',
        filter: 'filtre',
        group: 'commandes groupées',
        sort: 'trier les commandes',
    },
    calendarSettings: {
        title: {
            selectRegion: 'Calendar Setting - Select region to start',
        },
        placeholder: {
            selectZone: 'Select Zone',
            selectMonth: 'select month',
            selectBDays: 'select business days',
        },
        setMaxBooking: 'Set max booking date',
        subSetMaxBooking: '(How far ahead should customers be able to book)',
        monthFromToday: 'month(s) from today',
        submit: 'Envoyer',
        setMinBooking: 'Set earliest available date',
        subSetMinBooking:
            '(eg: 2 means the earliest available date will be after 2 business days from the date the delivery is being places)',
        includingToday: 'business days including today',
        messages: {
            changePostalCode:
                'Customized days above override the postal code schedules. If you need to change postal code schedules please email us at admin@shyftbase.com.',
            followingDate: 'the following date ',
            default: 'Change back to default service schedule based on postal codes.',
        },
        colorCodes: 'Color codes: ',
        disabledDays: 'disabled days',
        enabledDays: 'enabled days',
        select: 'select',
        enable: 'enable',
        disable: 'disable',
    },
    distributionZoneMapping: {
        subtitle: 'Distribution Zone Mapping - Select region to start',
        'Select Region': 'Select Region',
        'Select Distribution Zones': 'Type and Search Distribution Zone',
        'Currently Mapped Distribution Zones': 'Currently Mapped Distribution Zones',
        Update: 'Update',
        Reset: 'Reset',
        None: 'None',
        Updated: 'Updated {0} Distribution Zones',
        Added: 'Added {0} to Regions List',
    },
    accessTable: {
        accessLevels: "Niveaux d'accès",
        toggle: "Basculer la priorité d'accès",
        save: 'Enregistrer',
        searchByName: 'RECHERCHE',
        noUser: 'Aucun utilisateur trouvé',
        emailAdmin: '',
        name: 'Nom',
        access: 'Accès',
        'Create a new one': 'Create a new one',
        'User access updated': 'User access updated',
        Region: 'Region',
        Actions: 'Actions',
        Client: 'Client',
    },
    updateOrderInfo: {
        title: 'MISE À JOUR DE LA COMMANDE',
        startWindow: 'Date',
        selectedDate: 'Date',
        services: 'Services',
        products: 'Produits',
        additionalInfo: 'Informations complémentaires',
        submit: 'Envoyer',
        ok: 'OK',
        messages: {
            somethingWentWrong: "Quelque chose a mal tourné ! S'il vous plaît, essayez à nouveau.",
            pending: 'La commande a été mise à jour dans la liste des commandes en cours !',
        },
    },
    orderDetailModal: {
        requestInfo: "Demande d'informations (Déplacer vers 'Attention')",
        typeMessage: 'Veuillez taper votre message',
        approve: 'Approuver la commande',
        areYouSure: 'Etes-vous sûr ?',
        yes: 'Oui',
        no: 'Non',
        cancelOrder: 'Annuler la commande',
        update: 'Mise à jour',
        selectAction: 'Sélectionner une action/voir les détails',
    },
    orderDetail: {
        invoice: 'Facture / Numéro de commande',
        customer: 'Client',
        email: 'Email',
        phone1: 'Téléphone 1',
        phone2: 'Téléphone 2',
        products: 'Produits',
        services: 'Services',
        startWindow: 'Date',
        address: 'Adresse',
        pickupAddress: 'Adresse de ramassage',
        dispatchComments: 'Commentaires supplémentaires',
    },
    cancellationHandler: {
        selectReasonTitle: 'sélectionnez la raison',
        options: {
            RESCHEDULE: 'REPROGRAMMER',
            'NO STOCK': 'PAS DE STOCK',
            'CANCELLED BY CUSTOMER': 'ANNULÉ PAR LE CLIENT',
            'ADDRESS NOT FOUND': 'ADRESSE NON TROUVÉE',
            'ADULT NOT PRESENT': 'ADULTE ABSCENT',
            'PREVIOUSLY DONE': 'PRÉCÉDEMMENT FAIT',
            'BOOKING ERROR': 'ERREUR DE RÉSERVATION',
            'CANCELLED BY STORE': 'ANNULÉ PAR LE MAGASIN',
            'CANCELLED TO RESCHEDULE': 'ANNULÉ POUR REPROGRAMMER',
            'CREATED - NO STOCK': 'CRÉÉ - PAS DE STOCK',
            'DELIVERY TEAM ILLNESS / INJURY': "MALADIE / BLESSURE DE L'ÉQUIPE DE LIVRAISON",
            'DUPLICATE ORDER': 'COMMANDE EN DOUBLE',
            'FLY BY - NO PRODUCT AVAILABLE': ' Fly-By - AUCUN PRODUIT DISPONIBLE ',
            'INCORRECT ADDRESS': 'ADRESSE INCORRECTE',
            'INCORRECT ADDRESS - NOT LOADED ON TRUCK': 'ADRESSE INCORRECTE - NON CHARGÉ SUR LE CAMION',
            'INSUFFICIENT MANPOWER': 'EFFECTIFS INSUFFISANTS',
            'INSUFFICIENT MANPOWER - NOT LOADED ON TRUCK': 'EFFECTIF INSUFFISANT - NON CHARGÉ SUR LE CAMION',
            'NAH - OUTSIDE WINDOW': 'NAH - FENÊTRE EXTÉRIEURE ',
            'NO STOCK - MID MILE DELAY': 'PAS DE STOCK - RETARD DE MI-MILLE',
            'NO STOCK LOCATED AT HUB': 'PAS DE STOCK SITUÉ AU CENTRE',
            'OVERSIZED PRODUCTS(S)': 'PRODUIT(S) SURDIMENSIONNÉ(S)',
            'PRODUCT NOT LOADED': 'PRODUIT NON CHARGÉ',
            'PROPERTY DAMAGE': 'DOMMAGES MATÉRIELS',
            'PROPERTY NOT ACCESSIBLE': 'PROPRIÉTÉ NON ACCESSIBLE',
            'REFUSED - CONCEALED DAMAGE': 'REFUSÉ - DOMMAGES DISSIMULÉS',
            'REFUSED - DRIVER DAMAGE': 'REFUSÉ - DOMMAGES AU CONDUCTEUR',
            'REFUSED - INCORRECT PRODUCT ORDERED': 'REFUSÉ - PRODUIT INCORRECT COMMANDÉ',
            'REFUSED - INCORRECT PRODUCT SHIPPED': 'REFUSÉ - PRODUIT INCORRECT EXPÉDIÉ',
            'REFUSED - PRODUCT DEFECTIVE': 'REFUSÉ - PRODUIT DÉFECTUEUX',
            'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK': 'RESCHEDULED BY CUSTOMER - NOT LOADED ON TRUCK',
            'RESCHEDULED BY CUSTOMER': 'REPROGRAMMÉ PAR LE CLIENT',
            'VEHICLE ISSUES': 'Lave- vaisselle - NON CHARGÉ SUR LE CAMION',
            'WEATHER - NOT LOADED ON TRUCK': 'MÉTÉO - NON CHARGÉ SUR LE CAMION',
            'WEATHER ISSUES': 'LES PROBLÈMES MÉTÉOROLOGIQUES',
        },
    },
    orderInfo: {
        placeholder: {
            invoice: 'Facture/valeur',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            phone1: 'Téléphone 1',
            phone2: 'Téléphone 2',
            email: 'Email',
            appt: 'Appartement',
            unit: 'Unité #',
            street: 'Rue / Route',
            city: 'Ville / Village',
            postalCode: 'Code postal',
        },
    },
    bbUserAccessCell: {
        selectOrderType: 'Veuillez sélectionner le type de commande',
    },
    chatHistory: {
        title: "L'historique des chats",
        reply: 'Répondre',
        submit: 'Envoyer',
    },
    report: {
        dateRangeMsg: '* If date range is longer than 2 weeks, pleaes use `Download survey report - archive`',
        liveArchiveDataSourceMsg:
            '* To ensure you have the most recent results from the day before, please download the survey from the live data source.',
        download: 'Download survey reports',
        donwloadFromLiveData: 'Download survey report - live data',
        downloadData: 'Download data',
        viewReport: 'View Report',
        selectDateRange: 'Select Date Range',
        success: 'Report was successfully downloaded.',
        noDate: 'Date was not selected',
        noRecords: 'No records are found. Please set different date range and try again',
        noAuth: 'User not authorized. Only authorized staff can donwload report.',
        liveDataMsg:
            '* Live Data: Scope is limited to the live data only. Please use the archive extract of you need to download historical data.',
        'Download survey report - comments': 'Download survey report - comments',
    },
    fields: {
        Invoice: 'Invoice',
        OrderType: 'Order Type',
        StartWindow: 'Start Window',
        EndWindow: 'End Window',
        Products: 'Products',
        actions: 'Actions',
        OrderStatus: 'Order Status', // order status
        CustomerName: 'Customer Name',
        Phone1: 'Phone 1',
        Phone2: 'Phone 2',
        Email: 'Email',
        PickupAddress: 'Pickup Address',
        DropoffAddress: 'Dropoff Address',
        DriverNotes: 'Driver Notes',
        DriverServices: 'Driver Services',
        PlannedServices: 'Planned Services',
        StartedAt: 'Started At',
        ArrivedAt: 'Arrived At',
        CompletedAt: 'Completed At',
        BestBuyRobocallStatus: 'Robocall Status',
        BestBuySurveyQ1: 'Survey Q1',
        BestBuySurveyQ2: 'Survey Q2',
        BestBuySurveyQ3: 'Survey Q3',
        BestBuySurveyQ4: 'Survey Q4',
        BestBuySurveyQ5: 'Survey Q5',
        BestBuySurveyQ6: 'Survey Q6',
        BbGiftCustomerEmail: 'Gift Card Email',
        BbGiftAmount: 'Gift Card Amount',
        CancellationReason: 'Cancellation Reason',
        CalledCallCenter: 'Called CallCenter',
        BbCustomerEmailForSurvey: 'Survey Email',
        BbCustomerSurveyComment1: 'Survey Comment 1',
        BbCustomerSurveyComment2: 'Survey Comment 2',
        BestBuyRobocallTimestamp: 'Robocall Timestamp',
        Services: 'Services',
        Region: 'Region',
        DeliveryAssignee: 'Delivery Assignee',
        Vehicle: 'Vehicle',
        DispatchComments: 'Dispatch Comments',
        ServiceTime: 'Service Time',
        TotalTimeFromStart: 'Total Time From Start',
        // TextMsgLogs: "Text Message Logs",
        DispatchLog: 'Dispatch Log',
        Store: 'Store',
        Pod: 'POD',
        CustomerSignature: 'Customer Signature',
        DistributionZone: 'Distribution Zone',
        Client: 'Client',
    },
    vuetableDetailModal: {
        orderInfo: 'ORDER INFO',
        imageAttachment: 'IMAGE ATTACHMENT',
        productDetail: 'PRODUCT DETAIL',
        product: {
            Product: 'Product',
            QTY: 'QTY',
            SKU: 'SKU',
            WEIGHT: 'WEIGHT',
            MANUFACTURER: 'MANUFACTURER',
            VOLUME: 'VOLUME',
            SERIAL: 'SERIAL',
            MODEL: 'MODEL',
            BARCODE: 'BARCODE',
            COMMENT: 'COMMENT',
        },
        driverNotes: 'Driver Notes',
        textMsgLogs: 'Text Message Log',
        tooltip: {
            Name: 'Name',
            Phone: 'Phone',
            Products: 'Products',
            Accessorials: 'Accessorials',
            'Dispatch Comments': 'Dispatch Comments',
            'Delivery Address': 'Delivery Address',
            'Pickup Address': 'Pickup Address',
            'Start Window': 'Start Window',
        },
    },
    admin: {
        'Admin Settings': 'Réglages administrateur',
        'Billing Module Settings': 'Billing module',
        'Your Business, Your Way': 'Traitez vos affaires à votre manière',
        'Access Table': 'User Access Management',
        'Admin Action': 'Admin Action',
        'Calendar Settings': 'Calendar Settings',
        'Driver Settings': 'Driver Settings',
        'Distribution Zone Mapping': 'Distribution Zone Mapping',
        'Reports Recipients Setting': 'Reports Settings',
        'Client Settings': 'Customer Settings',
        'Fleet Settings': 'Fleet Settings',
        'Contactless Delivery Settings': 'Contactless Delivery Settings',
        'Metro Driver Settings': 'Metro Driver Settings',
        'Role Settings': 'Roles',
        'User Management': 'User Management',
        'Business Logic Center': 'Business Logic Center',
        ApiKeys: 'Manage API Keys',
        'Vendor Profile Settings': 'Vendor Profile Settings',
        'Contractor Profile Settings': 'Contractor Profile Settings',
        'General Settings': 'General Settings',
        'Notification Settings': 'Notification Settings',
        fields: {
            Yes: 'Yes',
            No: 'No',
            Cancel: 'Cancel',
            'Add New User': 'Add New User',
            'Create Vendor Profile': 'Create Vendor Profile',
            'Edit Vendor Profile': 'Edit Vendor Profile',
            'Delete Vendor Profile': 'Delete Vendor Profile',
            'Create Contractor Profile': 'Create Contractor Profile',
            'Edit Contractor Profile': 'Edit Contractor Profile',
            'Delete Contractor Profile': 'Delete Contractor Profile',
            'Business Unit': 'Business Unit',
            'Sub Business Unit': 'Sub Business Unit',
            'Company Name': 'Company Name',
            Active: 'Active',
            Escrow: 'Escrow',
        },
    },
    driverSettings: {
        Create: 'Create',
        Update: 'Update',
        Delete: 'Delete',
        'Type new name here': 'Type new name here',
        Confirm: 'Confirm',
        Created: 'Created {0}',
        Updated: 'Updated {0}',
        Deleted: 'Deleted',
        'Type Delivery Assignee ID': 'Type Delivery Assignee ID',
        'is not found': 'is not found',
        'Type New Contractor To Create': 'Type New Contractor To Create',
        'No Driver was found in system': 'No Driver was found in system',
    },
    driverInfo: {
        Name: 'Name',
        'Fleet Id': 'Fleet Id',
        Company: 'Company',
        Username: 'Username',
        Password: 'Password',
        'Read Only': 'Read Only',
    },
    newUserForm: {
        'Create a new user': 'Create a new user',
        'First Name': 'First Name',
        'First name is required': 'First name is required',
        'Invalid first name': 'Invalid first name',
        'Last Name': 'Last Name',
        'Last name is required': 'Last name is required',
        'Invalid last name': 'Invalid last name',
        'Store/Admin': 'Store / Admin',
        'Store is required': 'Store is required',
        Admin: 'Admin',
        'Admin is required': 'Admin is required',
        Phone: 'Phone',
        'Phone is required': 'Phone is required',
        'Type only numbers without country code': 'Type only numbers without country code',
        Email: 'Email',
        'Email is required': 'Email is required',
        'Invalid email': 'Invalid email',
        Reset: 'Reset',
        'Create user': 'Create user',
        'saved user': ' is saved with success!',
        'Update User': 'Update User',
        Close: 'Close',
    },
    notification: {
        accessDenied: 'Access denied. Please check your access level.',
    },
    reports: {
        'Select Report Type': 'Report Type',
        'Currently Assigned Recipients': 'Currently Assigned Recipients',
        'Type email here': 'Type email here...',
        'Type Recipients Email and Hit Enter': "Type Recipient's Email and Hit Enter",
        'Reicipients list updated': 'Reicipients list updated',
    },
    Billing: {
        Hello: 'Hello',
        'Last Month Overview': 'Last Month Overview',
        'Select Month': 'Select Month',
        'Download Supporting Data': 'Download Supporting Data',
        'Download Invoice': 'Download Invoice',
        'Total Orders': 'Total Orders',
        Commingled: 'Commingled',
        Dedicated: 'Dedicated',
        orders: 'orders',
        stops: 'stops',
        Scanned: 'Scanned',
        Returns: 'Returns',
        ViewInvoices: 'View Invoices',
        BackToOverView: 'Back To Overview',
        'Driver Requests': 'Driver Requests',
    },
    forgotPassword: {
        'Reset password link was sent your email':
            'Nous vous avons envoyé un email contenant un lien que vous pourrez utiliser pour créer un nouveau mot de passe.',
        'Reset password': 'Réinitialiser votre mot de passe',
        'Please type your email': 'Adresse courriel',
        'forgot password': 'Mot de passe oublié? ',
    },
    allFields: {
        actions: 'Actions',
        NumberOfDoorRemovals: 'Number Of Door Removals',
        NumberOfExtraPieces: 'Number Of Extra Pieces',
        NumberOfFridgeLineInstalls: 'Number Of Fridge Line Installs',
        NumberOfAdjustables: 'Number Of Adjustables',
        NumberOfPedInstalls: 'Number Of Ped Installs',
        NumberOfScrap: 'Number Of Scrap',
        NumberOfStacks: 'Number Of Stacks',
        NumberOfWasherDryerInstalls: 'Number Of Washer Dryer Installs',
        Charge100: 'Charge100',
        TotalHours2Man: 'Total Hours2Man',
        TotalHours3Man: 'Total Hours3Man',
        Charge50: 'Charge50',
        AddToCage: 'Add To Cage',
        DriverServices: 'Driver Services',
        ApprovedForBilling: 'Approved For Billing',
        ArchiveStatus: 'Archive Status',
        ArrivedAt: 'Arrived At',
        PlannedServices: 'Planned Services',
        Autobill: 'Autobill',
        AutoContractorBill: 'Auto Contractor Bill',
        AutoContractorBillEdited: 'Auto Contractor Bill Edited',
        Barcodes: 'Barcodes',
        BarcodesMatched: 'Barcodes Matched',
        BbCustomerEmailForSurvey: 'Bb Customer Email For Survey',
        BbCustomerSurveyComment1: 'Bb Customer Survey Comment1',
        BbCustomerSurveyComment2: 'Bb Customer Survey Comment2',
        BbGiftAmount: 'Bb Gift Amount',
        BbGiftCustomerEmail: 'Bb Gift Customer Email',
        BestBuyRobocallStatus: 'Best Buy Robocall Status',
        BestBuyRobocallTimestamp: 'Best Buy Robocall Timestamp',
        BestBuyRobocallSent: 'Best Buy Robocall Sent',
        BestBuySurveyQ1: 'Best Buy Survey Q1',
        BestBuySurveyQ2: 'Best Buy Survey Q2',
        BestBuySurveyQ3: 'Best Buy Survey Q3',
        BestBuySurveyQ4: 'Best Buy Survey Q4',
        BestBuySurveyQ5: 'Best Buy Survey Q5',
        BestBuySurveyQ6: 'Best Buy Survey Q6',
        BillingInfo: 'Billing Info',
        BillingValidated: 'Billing Validated',
        CalledCallCenter: 'Called Call Center',
        CityDistance: 'City Distance',
        Client: 'Client',
        CoastLocation: 'Coast Location',
        OrderStatus: 'Order Status',
        CompletedAt: 'Completed At',
        CompletedStops: 'Completed Stops',
        CancellationReason: 'Cancellation Reason',
        ContractorName: 'Contractor Name',
        ContractorPay: 'Contractor Pay',
        ContractorPayNotes: 'Contractor Pay Notes',
        CustomerComment: 'Customer Comment',
        CustomerName: 'Customer Name',
        CustomerRating: 'Customer Rating',
        CustomerSignature: 'Customer Signature',
        Deductions: 'Deductions',
        DeliveryAssignee: 'Delivery Assignee',
        DispatchClosed: 'Dispatch Closed',
        DispatchComments: 'Dispatch Comments',
        DispatchLog: 'Dispatch Log',
        DistributionZone: 'Distribution Zone',
        Driver: 'Driver',
        DriverAttachments: 'Driver Attachments',
        DriverNotes: 'Driver Notes',
        DriverTrackingLink: 'Driver Tracking Link',
        DropoffAddress: 'Dropoff Address',
        DropoffApt: 'Dropoff Apt',
        DropoffCityTown: 'Dropoff City Town',
        DropoffMapUrl: 'Dropoff Google Map Url',
        DropoffPostalCode: 'Dropoff Postal Code',
        DropoffStDr: 'Dropoff St Dr',
        DropoffUnit: 'Dropoff Unit',
        Email: 'Email',
        EndWindow: 'End Window',
        EtaLink: 'Eta Link',
        NumberOfExchanges: 'Number Of Exchanges',
        ExtraAttachments: 'Extra Attachments',
        GeoLocation: 'Geo Location',
        HourlyWork: 'Hourly Work',
        Invoice: 'Invoice',
        CoastInvType: 'Coast Inv Type',
        IsMetroCubeOrigin: 'Is Metro Cube Origin',
        KmDriven: 'Km Driven',
        LastArchived: 'Last Archived',
        LastAutobilled: 'Last Autobilled',
        LastMetroUpdate: 'Last Metro Update',
        LastTookanSync: 'Last Tookan Sync',
        Latitude: 'Latitude',
        Liabilities: 'Liabilities',
        LinkedOrder: 'Linked Order',
        LocationAccuracy: 'Location Accuracy',
        Longitude: 'Longitude',
        MetroNeedUpdate: 'Metro Need Update',
        NumberPastDoor: 'Number Past Door',
        NumberPriorToDoor: 'Number Prior To Door',
        NewOrderAttachments: 'New Order Attachments',
        NoCharge: 'No Charge',
        OpsBilling: 'Ops Billing',
        OpsMargin: 'Ops Margin',
        OutOfTown: 'Out Of Town',
        OverridePickupApt: 'Override Pickup Apt',
        OverridePickupCityTown: 'Override Pickup City Town',
        OverridePickupPostalCode: 'Override Pickup Postal Code',
        OverridePickupStDr: 'Override Pickup St Dr',
        OverridePickupUnit: 'Override Pickup Unit',
        Phone1: 'Phone1',
        Phone2: 'Phone2',
        ClientSubUnit: 'Client Sub Unit',
        PickupAddress: 'Pickup Address',
        PickupApt: 'Pickup Apt',
        PickupByLinehaul: 'Pickup By Linehaul',
        PickupCityTown: 'Pickup City Town',
        PickupMapUrl: 'Pickup Google Map Url',
        PickupPostalCode: 'Pickup Postal Code',
        PickupStDr: 'Pickup St Dr',
        PickupUnit: 'Pickup Unit',
        OrderType: 'Order Type',
        PickupCityDistance: 'Pickup City Distance',
        PickupGeoLocation: 'Pickup Geo Location',
        PickupLatitude: 'Pickup Latitude',
        PickupLocationAccuracy: 'Pickup Location Accuracy',
        PickupLongitude: 'Pickup Longitude',
        PlannedStops: 'Planned Stops',
        Pod: 'Pod',
        ProductCount: 'Product Count',
        ProductJson: 'Product Json',
        Products: 'Products',
        ProductsArrival: 'Products Arrival',
        PushTime: 'Push Time',
        DriverPushStatus: 'Driver Push Status',
        ReceivedInWh: 'Received In Wh',
        Region: 'Region',
        RequestedDeliveryWindow: 'Requested Delivery Window',
        ScannedBarcodes: 'Scanned Barcodes',
        ServiceTime: 'Service Time',
        ShippedOut: 'Shipped Out',
        Skus: 'Skus',
        StairsOver3rdFloor: 'Stairs Over3rd Floor',
        StartWindow: 'Start Window',
        StartedAt: 'Started At',
        OpsStatuses: 'Ops Statuses',
        Stem: 'Stem',
        StemKms: 'Stem Kms',
        Store: 'Store',
        Swamper: 'Swamper',
        SwamperEnd: 'Swamper End',
        SwamperStart: 'Swamper Start',
        TextMsgLogs: 'Text Msg Logs',
        TimeOffset: 'Time Offset',
        TookanStatus: 'Tookan Status',
        TookanId: 'Tookan Id',
        TotalTimeFromStart: 'Total Time From Start',
        Vehicle: 'Vehicle',
        UniqueOrderId: 'Unique Order Id',
        User: 'User',
        VaultId: 'Vault Id',
        Volume: 'Volume',
        WhirlpoolLoadId: 'Whirlpool Load Id',
        AutoBillEdited: 'Auto Bill Edited',
        AutoBillComments: 'Auto Bill Comments',
        ContractorBillingValidated: 'Contractor Billing Validated',
        FileUploadsDictionary: 'File Uploads Dictionary',
        _CreatedTimestamp: 'Created Timestamp',
        _UpdatedTimestamp: 'Updated Timestamp',
        _ImportedTimestamp: 'Imported Timestamp',
        _Operation: 'Operation',
    },
    portal: {
        'Main Portal': 'Portail Principal',
        'TV Portal': 'TV',
        'Same Day Portal': 'Jour Même',
    },
    contactless: {
        'Type and select zone to map clients': 'Type and select zone to map clients',
        'not exist': 'not exist',
        'Currently Mapped Clients': 'Currently Mapped Clients',
        Updated: 'Updated contactless delivery settings for {0}',
    },
    clientSettings: {
        'Create a new client': 'Create a new client',
        activate: 'Activate',
        deactivate: 'Deactivate',
        activated: 'activated',
        deactivated: 'deactivated',
        'client name is required': 'client name is required',
        'Include in Inventory': 'Include in Inventory',
        'Client Name': 'Client Name',
        'Successfully created': 'Successfully created',
        'Client already exists': 'Client already exists',
        'No region found': 'No region found',
        'Region is required': 'Region is required',
        'Type to search region': 'Type to search region',
        Inventory: 'Inventory',
        'Reverse Logistics': 'Logistique des retours',
        'Include in Reverse Logistics': 'Dans la logistique des retours',
        'Send Survey': 'Envoyer',
        Yes: 'Oui',
        No: 'Non',
        'Billing Code': 'Le code de facturation',
        'Default address': 'Adresse par défaut - Bientôt disponible!',
        'Save changes': 'Enregistrer les modifications',
        'Upload Survey Logo': 'Télécharger un logo',
        'Remove Survey Logo': 'Supprimer',
    },
    ikeaDriverSetting: {
        'Select type to proceed': 'Select type to proceed',
        loading: 'loading...',
        drivers: 'drivers',
        contractors: 'contractors',
        'Create New': 'Create New',
        Update: 'Update',
        Delete: 'Delete',
        Region: 'Region',
        ID: 'ID',
        Name: 'Name',
        Company: 'Company',
        'Company Name': 'Company Name',
        Active: 'Active',
        Submit: 'Submit',
        Cancel: 'Cancel',
        'successfully updated': 'successfully updated',
        'successfully removed': 'successfully removed',
        'No options matching the keyword': 'No options matching the keyword',
        Select: 'Select',
    },
    dateRegion: {
        'dateField': 'Date Field',
        'Records': 'Afficher les Commandes',
    }
}