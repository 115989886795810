import axios from 'axios'
import { getIdToken, onAuthStateChanged, signOut } from 'firebase/auth'
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import { GlobalContext } from '../core/context/globalContext'
import { auth, rolesCollection } from '../services/firebase/firebaseConfig'
import styles from './mainRoutes.styles.module.css'
import { useParams } from 'react-router-dom'
import Nav from '../components/Nav/Nav'

const Admin = lazy(() => import('../pages/Admin'))
const AdminRoleSettings = lazy(() => import('../pages/Admin/view/RoleSettings'))
const AdminUserRoleSettings = lazy(() => import('../pages/Admin/view/UserRoleSettings'))
const BillingAdmin = lazy(() => import('../pages/Admin/view/components/BillingAdmin'))
const ChangePassword = lazy(() => import('../pages/DateRegion/changePassword'))
const Conditions = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Conditions/Conditions'))
const ContractorProfileSettings = lazy(() => import('../pages/Admin/view/ContractorProfileSettings'))
const DateRegion = lazy(() => import('../pages/DateRegion'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const FSCRates = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/FSCRates/FSCRates'))
const IkeaPostalCode = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/IkeaPostalCode/IkeaPostalCode'))
const LiveConnect = lazy(() => import('../pages/LiveConnect'))
const Login = lazy(() => import('../pages/Login'))
const NotificationSettings = lazy(() => import('../pages/Admin/view/NotificationSettings'))
const Optimizer = lazy(() => import('../pages/Optimizer'))
const MapFleet = lazy(() => import('../pages/MapFleet'))
const OrderTable = lazy(() => import('../pages/OrderTable'))
const RateTableSettings = lazy(() => import('../pages/Admin/view/components/BillingAdmin/RateTableSettings'))
const Reports = lazy(() => import('../pages/Reports'))
const ReportsRecipientsSettings = lazy(() => import('../pages/Admin/view/ReportsRecipientsSettings'))
const Services = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Services/Services'))
const SubStatus = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/SubStatus/SubStatus'))
const TiersEscrow = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/TiersEscrow'))
const TrackOrder = lazy(() => import('../pages/TrackOrder'))
const VenderProfileSettings = lazy(() => import('../pages/Admin/view/VendorProfileSettings'))
const WeeklyFSCRates = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/WeeklyFSCRates/WeeklyFSCRates'))
const Zones = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Zones/Zones'))
const CalendarSettings = lazy(() => import('../pages/Admin/view/CalendarSettings'))
const MainRoutes = () => {
    const { user: { updateUser, user },
        table: { updateActions, updateRole }
    } = useContext(GlobalContext)
    const location = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [openSnackbar] = useSnackbar()

    const { trackId } = useParams<any>()
    const [messages] = useState([
        'Optimizing inventory management can increase profitability by 20%',
        '76% of companies experienced supply chain disruptions in 2020',
        'Supply chain visibility is a top priority for 84% of companies',
        'Supply chain digitalization can reduce costs by up to 30%',
        'Blockchain technology is being used to improve supply chain transparency',
        'The logistics industry is expected to grow to $12 trillion by 2025',
        'In 2023, the global 3PL market is expected to reach $1.3 trillion',
        'More than 50% of retailers plan to implement AI in their supply chain by the end of 2023',
        'By the end of 2023, 30% of companies will adopt a digital twin of their supply chain',
        'In 2022, last-mile delivery was expected to account for 53% of total delivery costs',
        'In 2022, 40% of companies implemented a digital twin of their supply chain',
        'Loading'
    ]);
    const [message, setMessage] = useState(messages[Math.floor(Math.random() * messages.length)]);

    useEffect(() => {
        let timeOut;
        const intervalId = setInterval(() => {
            setMessage(messages[Math.floor(Math.random() * messages.length)]);
        }, 3000);
        try {
            onAuthStateChanged(auth, async (user) => {
                setLoading(true)
                try {
                    if (user) {
                        const idToken = await getIdToken(user, true)
                        const validateLink = process.env.REACT_APP_VALIDATE_EMAIL || ''
                        /*
                        {
                            role,
                            validAccess,
                            driverProfileDetails,
                            vendorProfileDetails,
                        }
                        */
                        const validationResult = await axios({
                            url: validateLink,
                            method: 'POST',
                            data: {
                                email: user.email,
                                token: idToken,
                                customerId: process.env.REACT_APP_CLIENT,
                                component: 'PORTAL',
                            },
                        })
                        /* fetch vendor and driver profiles from the IDs that come in for the user */
                        /* fetch role from the ID for the user */

                        if (!validationResult) {
                            setLoading(false)
                            updateUser(null)
                            updateRole({})
                            await signOut(auth)
                            return
                        }
                        if (validationResult.data && validationResult.data.validAccess === 'VALID_EMAIL') {
                            updateUser(user)
                            const docRef = await rolesCollection.getDocById(validationResult.data.role)
                            updateRole(docRef?.data())
                            updateActions(docRef?.data()?.actions)
                            /*
                                check if user is first time logging in via a temp password or not
                                if it is reroute them to changePass so they can change pass themselves
                            */
                            if (user && !user.photoURL || !user?.photoURL?.includes('passChanged')) {
                                history.push('/changePassword')
                            } else if (window.location.pathname && window.location.pathname.toLowerCase().includes('login')) {
                                timeOut = setTimeout(() => history.push('/mainContainer'), 2)
                            }
                            setLoading(false)
                        } else if (validationResult.data && validationResult.data.validAccess === 'INVALID_EMAIL') {
                            openSnackbar(`User is not valid, please contact Admin`)
                            setLoading(false)
                            updateUser(null)
                            updateRole({})
                            await signOut(auth)
                            return
                        } else {
                            openSnackbar(`Invalid Account`)
                            setLoading(false)
                            updateUser(null)
                            updateRole({})
                            await signOut(auth)
                            return
                        }
                    } else {
                        if (location.pathname && location.pathname.includes('forgotPassword')) history.push('/forgotPassword')
                        else if (location.pathname && location.pathname.includes('trackorder')) history.push('/trackorder/:trackId')
                        else history.push('/login')
                        setLoading(false)
                    }
                } catch (error) {
                    if (location.pathname && location.pathname.includes('forgotPassword')) history.push('/forgotPassword')
                    else if (location.pathname && location.pathname.includes('trackorder')) history.push('/trackorder/:trackId')
                    else history.push('/login')
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            return
        }
        return () => {
            clearTimeout(timeOut)
            clearInterval(intervalId);
        }
    }, [])

    return (
        <div className={styles.app}>
            {(
                <div className="shyftbase_main_layout">
                    {user && <div>{<Nav />}</div>}
                    {!user && <div></div>}
                    <div className="shyftbase_main_layout_content">
                        {loading && (
                            <section className="hero is-half-height has-text-centered fadein">
                                <div className="hero-body">
                                    <div className="container has-text-centered">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FShyftBase-Logo-A%20(2).png?alt=media&token=4982178b-3878-4588-8f35-40d70b692c1b"
                                            alt="Shyftbase logo"
                                            width={350}
                                        />
                                        <p className='is-size-7'>{message}</p>
                                    </div>
                                </div>
                            </section>
                        )}
                        <div style={{ overflow: 'hidden' }} >
                            {!loading && (
                                <>
                                    <Suspense fallback={<p>Loading...</p>}>
                                        {location.pathname && (location.pathname === '/' || location.pathname === '/mainContainer') && (
                                            < DateRegion />
                                        )}
                                        {location.pathname && location.pathname === '/analytics' && (
                                            <Reports />
                                        )}
                                        {location.pathname && location.pathname === '/liveConnect' && (
                                            <LiveConnect />
                                        )}
                                        {location.pathname && location.pathname === '/admin' && (
                                            <Admin />
                                        )}
                                        {location.pathname && location.pathname === '/records' && (
                                            <OrderTable />
                                        )}
                                        {location.pathname && location.pathname === '/dateRegion' && (
                                            <DateRegion />
                                        )}
                                        {location.pathname && location.pathname === '/login' && (
                                            <Login />
                                        )}
                                        {location.pathname && location.pathname === '/forgotPassword' && (
                                            <ForgotPassword />
                                        )}
                                        {location.pathname && location.pathname === '/changePassword' && (
                                            <ChangePassword />
                                        )}
                                        {location.pathname && location.pathname === '/routes' && (
                                            <Optimizer />
                                        )}
                                        {location.pathname && location.pathname === '/mapFleet' && (
                                            <MapFleet />
                                        )}
                                        {location.pathname && location.pathname === `/trackorder` && (
                                            <TrackOrder trackId={""} />
                                        )}
                                        {location.pathname && location.pathname === `/trackorder/${trackId}` && (
                                            <TrackOrder trackId={trackId || ""} />
                                        )}
                                        {location.pathname && location.pathname === '/admin/role' && (
                                            <AdminRoleSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/userRole' && (
                                            <AdminUserRoleSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/vendor' && (
                                            <VenderProfileSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/contractor' && (
                                            <ContractorProfileSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/reports' && (
                                            < ReportsRecipientsSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/notifications' && (
                                            < NotificationSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing' && (
                                            < BillingAdmin />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/ratetable' && (
                                            < RateTableSettings />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/zones' && (
                                            < Zones />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/fscrates' && (
                                            < FSCRates />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/weeklyfscrates' && (
                                            < WeeklyFSCRates />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/services' && (
                                            < Services />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/substatus' && (
                                            < SubStatus />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/conditions' && (
                                            < Conditions />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/tiersEscrow' && (
                                            < TiersEscrow />
                                        )}
                                        {location.pathname && location.pathname === '/admin/billing/ikeapostalcode' && (
                                            < IkeaPostalCode />
                                        )}
                                        {location.pathname && location.pathname === '/admin/calendarSettings' && (
                                            < CalendarSettings />
                                        )}
                                    </Suspense>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    )
}
export default React.memo(MainRoutes)
