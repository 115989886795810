
import {
    collection, addDoc, query, getDocs, getDoc, doc, updateDoc, where, limit
} from 'firebase/firestore'

class Notification {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    where(fieldPath: string, opStr: any, value: any) {
        return ({
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
            where: (innerFieldPath: string, innerOpStr: any, innerValue: any) => {
                return ({
                    limit: (numLimit: number) => ({
                        get: async () => {
                            const q = query(this.collectionInstance, where(fieldPath, opStr, value), where(innerFieldPath, innerOpStr, innerValue), limit(numLimit))
                            const querySnapshot = await getDocs(q)
                            return querySnapshot
                        }
                    })
                })
            }
        })
    }

    doc(docId: string): any {
        return ({
            update: async (data: any) => {
                try {
                    const ref = doc(this.collectionInstance, docId)
                    return await updateDoc(ref, data)
                } catch (err) {
                    console.error(err)
                    return
                }
            },
            collection: (collectionId: string) => ({
                add: async (data: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    const coll = collection(ref, collectionId)
                    return addDoc(coll, data)
                }
            }),
            get: async () => {
                const ref = doc(this.collectionInstance, docId)
                const document = await getDoc(ref)
                return document
            }
        })
    }

    async add (data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }
}

export default (collection: any) => {
    return new Notification(collection)
}
